import { API } from './'

export const upsertmemes = (payload) => {
  return API.post(
    'upsertmemes',
    payload,
  )
};

export const getmemes = (payload) => {
  return API.post(
    'getmemesuser',
    payload,
  )
};


export const upsertcomment = (payload) => {
  return API.post(
    'upsertcomment',
    payload,
  )
};

export const upserttweet = (payload) => {
  return API.post(
    'uploadtweet',
    payload,
  )
};


export const removememe = (payload) => {
  return API.post(
    'removememe',
    payload,
  )
};

export const updatememe = (payload) => {
  return API.post(
    'updatememe',
    payload,
  )
};



export const removecomment = (payload) => {
  return API.post(
    'removecomment',
    payload,
  )
};
export const removelike = (payload) => {
  return API.post(
    'removelike',
    payload,
  )
};


export const upsertlike = (payload) => {
  return API.post(
    'upsertlike',
    payload,
  )
};


export const upsertreport = (payload) => {
  return API.post(
    'upsertreport',
    payload,
  )
};


export const uploadmemes = (payload) => {
  return API.post(
    'uploadmemes',
    payload,
  )
};
