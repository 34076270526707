import React, { useContext, useState, useEffect } from "react";

import { Link, Redirect, NavLink, useNavigate, useLocation } from "react-router-dom";

import { AppContext } from '../context/AppContext';
import jwt_decode from "jwt-decode";
const Header = (props) => {
  const context = useContext(AppContext);
  const location = useLocation();
  console.log(location.pathname);

  if (localStorage.getItem("token")) {
    var token = localStorage.getItem("token");

    var decoded = jwt_decode(token);
  }

  const navigate = useNavigate();

  const [style, setStyle] = useState("sidenav");
  var isLoggedIn = localStorage.getItem("isLoggedIn") || false;
  var emailverified = localStorage.getItem("emailverified") || false;


  const handleClickLogout = () => {
    console.log('tet')
    localStorage.removeItem("userid");
    localStorage.removeItem("token");
    localStorage.removeItem("emailVerified");
    localStorage.removeItem("avatar");
    localStorage.setItem("isLoggedIn", false);

    navigate("/login");

  };


  // state object contains the data
  return (

    <div class="header_block">

      <div class="header_right">
        {/* <div class="search">
          <input type="text" placeholder="Search  Meme" />
        </div> */}

        {!isLoggedIn && (
          <Link to="/login" class="button">Login</Link>)}



        {location.pathname != "/home" &&


          <Link to="/home" class="button">Home</Link>
        }


        {location.pathname != "/trend" &&
          <Link to="/trend" class="button">Trending</Link>

        }
        {isLoggedIn && (
          <Link to="/trend" class="button">My Memes</Link>)}
        {isLoggedIn && (
          <Link to="/addmeme" class="button">Create Meme</Link>)}
        <span onClick={() => setStyle('sidenav sidenavexpand')} class="material-icons mobile_menu">
          menu
        </span>
        <div id="mySidenav" class={style}>





          <a href="javascript:void(0)" class="closebtn" onClick={() => setStyle('sidenav')}
          >&times;</a
          >
          {/* <img src={context.userData.avatar[0].file}></img>
          <div class="form-group">

            <label>{context.userData.firstName + ' ' + context.userData.lastName}</label> </div>
          <div class="form-group">  <label>{context.userData.userName}</label> </div>
          <div class="form-group">  <label>{context.userData.emailAddress}</label>

          </div> */}
          {!isLoggedIn && (
            <a href="/login">Login</a>
          )}
          {isLoggedIn && (
            <a href="javascript:void(0)" onClick={handleClickLogout}>Logout</a>
          )}
          {/* {isLoggedIn && !emailverified && (
            <a href="/login">Services</a>
          )} */}
          {/* {isLoggedIn && (
            <a href="/login">Signup</a>
          )} */}
          {/* {isLoggedIn && emailverified && (
            <a href="/login">Services</a>
          )} */}
          {/* <Link to={{
            pathname: '/login',
            search: "code",
            hash: "#the-hash",
            state: { fromDashboard: true }
          }}>Signup</Link> */}

          {/* <a href="/addmeme">Clients</a>
          <a href="/addmeme">Contact</a> */}
        </div>
      </div>
    </div >
  );
};

export default Header;