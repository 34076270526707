import { API } from './'

export const getsignedurl = (payload) => {
  return API.post(
    'getsignedurl',
    payload,
  )
};

export const signupUser = (payload) => {
  return API.post(
    'signup',
    payload,
  )
};

export const uploadfile = (payload) => {
  return API.post(
    'uploadfile',
    payload,
  )
};

export const loginUser = (payload) => {
  return API.post(
    'login',
    payload,
  )
};

export const verifyEmail = (payload) => {
  return API.post(
    'confirmuser',
    payload,
  )
};

export const inviteEmail = (payload) => {
  return API.post(
    'sendemail',
    payload,
  )
};

export const deleteUser = (payload) => {
  return API.post(
    'deleteuser',
    payload,
  )
};

export const resendConfirmationCode = (payload) => {
  return API.post(
    'resendcode',
    payload,
  )
};

export const forgotpasswordCode = (payload) => {
  return API.post(
    'forgotpasswordcode',
    payload,
  )
};

export const forgotpassword = (payload) => {
  return API.post(
    'forgotpassword',
    payload,
  )
};

export const changepassword = (payload) => {
  return API.post(
    'changepassword',
    payload,
  )
};

export const countryList = (payload) => {
  return API.get(
    'search/countrylist',
    payload,
  )
};

export const businessFunctionlist = (payload) => {
  return API.get(
    'search/businessfunctionlist',
    payload,
  )
};

export const companyList = (payload) => {
  return API.get(
    'search/companylist',
    payload,
  )
};

export const currencyList = (payload) => {
  return API.get(
    'search/currencylist',
    payload,
  )
};

export const ethnicityList = (payload) => {
  return API.get(
    'search/ethnicitylist',
    payload,
  )
};

export const genderList = (payload) => {
  return API.get(
    'search/genderlist',
    payload,
  )
};

export const industryList = (payload) => {
  return API.get(
    'search/industrylist',
    payload,
  )
};

export const referencesourceList = (payload) => {
  return API.get(
    'search/referencesourcelist',
    payload,
  )
};

export const sectorList = (payload) => {
  return API.get(
    'search/sectorlist',
    payload,
  )
};
