import React, { Component } from "react";
import {
  BrowserRouter as Router, Route, Routes,
  useLocation,
  Navigate
} from 'react-router-dom';
import Home from "./pages/home";
import Login from "./pages/login";
import LoginOld from "./pages/loginold";
import AddMeme from "./pages/addmeme";
import Trend from "./pages/trendmeme";
import Terms from "./pages/terms";
import Privacy from "./pages/privacy";
import AboutUs from "./pages/aboutus";
import MyMemes from "./pages/mymemes";
import Users from "./pages/users";
import AppContextProvider from './context/AppContext'
import { Loader, Notification, toaster } from 'rsuite';
import ChangePassword from "./pages/changepassword";
export default function App() {


  return (
    <AppContextProvider>
      <Router>
        <Routes>
          <Route exact path='/' element={<Home />} />
          <Route exact path='/login' element={<Login />} />
          <Route exact path='/loginold' element={<LoginOld />} />
          <Route exact path='/addmeme' element={<RequireAuth><AddMeme /></RequireAuth>} />
          <Route exact path='/:memeid' element={<Home />} />
          <Route exact path='/home' element={<Home />} />
          <Route exact path='/mymemes' element={<MyMemes />} />
          <Route exact path='/trend' element={<Trend />} />
          <Route exact path='/terms' element={<Terms />} />
          <Route exact path='/privacy' element={<Privacy />} />
          <Route exact path='/aboutus' element={<AboutUs />} />
          <Route exact path='/users' element={<Users />} />
          <Route exzct path='/changepassword' element={<ChangePassword />} />
        </Routes>
      </Router>
    </AppContextProvider>
  );

}

// export default App;

function RequireAuth({ children }: { children: JSX.Element }) {

  let location = useLocation();

  var isLoggedIn = localStorage.getItem("isLoggedIn");

  if (isLoggedIn == 'false') {

    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;

}