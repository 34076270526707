

 import 'rsuite/dist/rsuite.min.css'

// import "./assets/css/icon.css"
//  import "./assets/css/bootstrap.min.css"

import "./assets/css/theme.css"
// import "./assets/js/bootstrap.bundle.min.js"

import 'bootstrap/dist/css/bootstrap.min.css'
import "@popperjs/core";
// import 'jquery/dist/jquery.min.js'
// import 'bootstrap/dist/js/bootstrap.min.js'
import "bootstrap";
import React from 'react';
import ReactDOM from 'react-dom';
// import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
