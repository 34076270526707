import React, { useContext, useState, setState, useEffect, useLayoutEffect } from 'react';
import Avatar from 'react-avatar-edit';
import { signupUser, uploadfile, loginUser, verifyEmail, resendConfirmationCode, forgotpassword, forgotpasswordCode } from '../api/auth'
import axios from 'axios';
import { Link, Navigate } from 'react-router-dom';
import appLogo1 from '../assets/images/fplogo.jpg';
import imgclock from '../assets/images/clock.png';
import imgplant from '../assets/images/plant.png';
import youthLogo from '../assets/images/forgotPassword_chellam.svg';
import { AppContext } from '../context/AppContext';
import { login } from "../context/actions/Auth";
import { useNavigate, useLocation } from "react-router-dom";
import _, { isNull } from "lodash";
import { useToaster, Loader, Notification, Message } from 'rsuite';
import ReactTooltip from 'react-tooltip';
import Modal from 'react-modal';
// import 'rsuite/dist/rsuite.min.css'
// import default style

const Login = (props) => {

    const toaster = useToaster();
    // useEffect(() => {
    //     toaster.push(<Message showIcon type={'error'}>message</Message>, {
    //         placement: 'topCenter'
    //     });
    // });



    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            padding: '360px',
            marginRight: '-50%',
            background: 'rgba(0,0,0,0.5)',
            transform: 'translate(-50%, -50%)',
        }

    };

    const [isChecked, setIsChecked] = useState(false);
    const handleOnChange = () => {
        setIsChecked(!isChecked);
    };

    const [state, setState] = useState({
        firstName: '',
        lastName: '',
        emailAddress: '',
        userName: '',
        password: '',
        confirmPassword: '',
        loading: false,
        uitype: 'login',
        required: false,
        code: '',
        hidealert: true,
        forgotcodesent: false,
        infomessage: ''
    })

    const {
        firstName,
        lastName,
        emailAddress,
        userName,
        password,
        confirmPassword,
        loading,
        uitype,
        required,
        code,
        hidealert,
        forgotcodesent,
        infomessage
    } = state
    const context = useContext(AppContext);
    const [type, setType] = React.useState('info');
    const [placement, setPlacement] = React.useState('topCenter');
    // const [infomessage, setinfomessage] = React.useState('');


    const message = (
        <Message showIcon type={type}>
            {infomessage}
        </Message>
    );
    let location = useLocation();

    let frompage = location.state?.from?.pathname;

    const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }
    const navigate = useNavigate();

    const [emailError, setEmailError] = useState(false);
    const [preview, setpreview] = useState(null);

    function iscognitoPassword(val) {

        if (/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-\"!@#%&\/,><\':;|_~`])\S{8,99}$/.test(val)) {

            return true;
        }
        return false;
    }




    const disabled =

        !emailAddress ||

        !password


    const disabledsignup =

        !emailAddress ||
        !password ||
        !confirmPassword ||
        !firstName ||
        !iscognitoPassword(password) ||
        !userName ||
        !isChecked
        // ||        !preview 
        || password != confirmPassword

    const disabledcode =

        !emailAddress ||

        !code

    const disabledpasswordcode =

        !password ||
        !confirmPassword ||
        !code
        || password != confirmPassword



    const handleChange = (key, value) => {
        setState(pre => ({ ...pre, [key]: value }))
    }




    const handleSubmit = async event => {




        if (disabled) {
            handleChange('required', true)
            handleChange('hidealert', false)
            return;
        }
        handleChange('hidealert', true)
        handleChange('required', false)
        handleChange('loading', true)

        const payload = {


            emailAddress,

            password

        }






        let res = {};
        try {
            res = await loginUser(payload);
            if (res.data.statusCode === 200) {


                handleChange('loading', false)

                localStorage.setItem('userid', res.data.result.user._id);
                localStorage.setItem('useremail', res.data.result.user.emailAddress);
                localStorage.setItem('token', res.data.result.token);
                localStorage.setItem('isLoggedIn', true);
                localStorage.setItem('isadmin', res.data.result.user.isadmin || false);
                if (res.data.result.user.avatar[0]) {
                    localStorage.setItem('avatar', res.data.result.user.avatar[0].file);
                }
                context.setUserData(res.data.result.user);
                // if (!res.data.result.user.emailVerified) {
                //     localStorage.setItem('emailVerified', res.data.result.user.emailVerified);
                //     toaster.push(<Message showIcon type={'error'}> Email Not Verified</Message>, {
                //         placement: 'topCenter'
                //     });
                //     // handleChange('uitype', 'code')
                //     // openModal();
                // }
                // else {
                if (frompage) { navigate(frompage); }
                else { navigate("/home"); }

                //  }

            }
        } catch (err) {

            console.log(err);

            if (err.message == "UserNotConfirmedException: User is not confirmed.") {
                toaster.push(<Message showIcon type={'error'}>Please verify your email a link has been sent to your email</Message>, {
                    placement: 'topCenter'
                });
                const payload = {


                    emailAddress



                }
                await resendConfirmationCode(payload);


            }
            else {

                toaster.push(<Message showIcon type={'error'}>Invalid Email or Password</Message>, {
                    placement: 'topCenter'
                });

                handleChange('loading', false)
                handleChange('uitype', 'login');
                // handleChange('emailAddress', "")
                // handleChange('password', "")
            }

        }






    };

    const handleSignupSubmit = async event => {

        console.log('handleSignupSubmit');

        if (disabledsignup) {
            handleChange('required', true)
            handleChange('hidealert', false)
            return
        }
        handleChange('hidealert', true)
        handleChange('required', false)
        handleChange('loading', true)

        const payload = {
            emailAddress,
            password,
            firstName,
            lastName,
            userName

        }

        payload.avatar = preview;
        payload.isadmin = false;


        let res = {};
        try {
            res = await signupUser(payload);

            if (res.data.statusCode === 200) {

                localStorage.setItem('userid', res.data.result._id);
                if (res.data.result.avatar[0]) {
                    localStorage.setItem('avatar', res.data.result.avatar[0].file);
                }

            }
            context.setUserData(res.data.result);
            toaster.push(<Message showIcon type={'info'}>Please verify your email a link has been sent to your email</Message>, {
                placement: 'topCenter'
            });

            handleChange('loading', false)
            handleChange('uitype', 'login')
            // openModal();
        } catch (err) {
            console.log(err);
            handleChange('loading', false)


            setType('error');

            setState({
                infomessage: err.message,

            }, () => {
                toaster.push(err.message, { placement });

            });


        }






    };
    const handleconfirmSubmit = async event => {


        if (disabledcode) {
            handleChange('required', true)
            return
        }

        handleChange('required', false)
        handleChange('loading', true)

        const payload = {


            emailAddress,

            code
        }

        payload._id = localStorage.getItem("userid") || '';


        let res = {};
        try {
            res = await verifyEmail(payload);
            if (res.data.statusCode === 200) {


                setType('success');
                // setinfomessage('Email Verified');
                // toaster.push('Email Verified', { placement });
                setState({
                    infomessage: 'Email Verified',

                }, () => {
                    toaster.push('Email Verified', { placement });

                });


                localStorage.setItem('emailVerified', true);
                handleChange('loading', false)
                handleChange('uitype', 'login')
                closeModal();
            }
        } catch (err) {

            handleChange('loading', false)




            setType('error');
            // setinfomessage(err.message);
            // toaster.push(message, { placement });
            setState({
                infomessage: err.message,

            }, () => {
                toaster.push(err.message, { placement });

            });

        }






    };
    const handleresend = async event => {
        event.preventDefault();

        if (!emailAddress) {
            handleChange('required', true)
            return
        }

        handleChange('required', false)
        handleChange('loading', true)

        const payload = {


            emailAddress

        }





        let res = {};
        try {
            res = await resendConfirmationCode(payload);
            if (res.data.statusCode === 200) {


                setType('info');
                // setinfomessage('Code Sent');
                // toaster.push('Code Sent', { placement });
                setState({
                    infomessage: 'Code Sent',

                }, () => {
                    toaster.push('Code Sent', { placement });

                });

                handleChange('loading', false)

            }
        } catch (err) {

            handleChange('loading', false);

            setType('error');
            // setinfomessage(err.message);
            // toaster.push(message, { placement });
            setState({
                infomessage: err.message,

            }, () => {
                toaster.push(err.message, { placement });

            });


        }






    };


    const handlepasswordcode = async event => {


        if (!emailAddress) {
            handleChange('required', true)
            return
        }

        handleChange('required', false)
        handleChange('loading', true)

        const payload = {


            emailAddress

        }





        let res = {};
        try {
            res = await forgotpasswordCode(payload);
            if (res.data.statusCode === 200) {


                setType('info');
                // setinfomessage('Code Sent');
                // toaster.push('Code Sent', { placement });
                setState({
                    infomessage: 'Code Sent',

                }, () => {
                    toaster.push('Code Sent', { placement });

                });


                handleChange('loading', false)
                handleChange('forgotcodesent', true);
                handleChange('emailAddress', emailAddress);
            }
        } catch (err) {
            closeModal();
            handleChange('loading', false)

            console.log(err, 'password issue');
            setEmailError(true);

            setType('error');
            // setinfomessage(err.message);
            // toaster.push(message, { placement });
            setState({
                infomessage: err.message,

            }, () => {
                toaster.push(err.message, { placement });

            });

        }






    };

    const handlepasswordchange = async event => {


        if (disabledpasswordcode) {
            handleChange('required', true)
            return;
        }

        handleChange('required', false)
        handleChange('loading', true)

        const payload = {


            emailAddress


        }

        payload.verificationCode = code;
        payload.newPassword = password;


        let res = {};
        try {
            res = await forgotpassword(payload);
            console.log('Res', res)
            if (res.data.statusCode === 200) {


                setType('info');


                setState({
                    infomessage: 'Password changed please login',

                }, () => {
                    toaster.push('Password changed please login', { placement });

                });

                // setinfomessage('Password Changed Please Login');
                // toaster.push('Password Changed Please Login', { placement });

                handleChange('loading', false)
                handleChange('uitype', 'login')
                closeModal();
            }
        } catch (err) {
            closeModal();
            handleChange('loading', false)


            setType('error');
            // setinfomessage(err.message);
            // toaster.push(message, { placement });
            setState({
                infomessage: err.error,

            }, () => {
                toaster.push(err.error, { placement });

            });


        }






    };



    const onClose = () => {
        setpreview(null)

    }

    const onCrop = (preview) => {
        setpreview(preview);


    }

    const onBeforeFileLoad = (elem) => {

        const size =
            (elem.target.files[0].size / 1024 / 1024).toFixed(2);

        if (size > 2) {




            setType('error');
            // setinfomessage("Avatar must be less than the size of 2MB");
            // toaster.push("Avatar must be less than the size of 2MB", { placement });
            setState({
                infomessage: "Avatar must be less than 2MB",

            }, () => {
                toaster.push(infomessage, { placement });

            });

            elem.target.files[0] = null;
        }




    }


    return (

        <div class="login_bg">
            <div class="clock"><img src={imgclock} alt="" /></div>
            <div class="plant"><img src={imgplant} alt="" /></div>

            {(function () {
                if (uitype == 'login') {


                    return <div class="login_form ">
                        <div class="form_logo"> <a href="/home"> <img src={appLogo1} /></a></div>
                        <div class="username">
                            <div class="form__group ">
                                <input type="email" id="email" class="form__field" placeholder="Email address" onChange={e => handleChange('emailAddress', e.target.value)} autoComplete="off">

                                </input>
                                <label for="email" class="form__label">Email address</label>
                            </div>
                            {required && !emailAddress && !hidealert && (
                                <div class="alert alert-danger clm_alert">
                                    <small> Email Address is required!</small>
                                    <a onClick={() => { handleChange('hidealert', true); }}><span class="material-icons">close</span></a>
                                </div>
                            )}
                        </div>


                        <div class="password">
                            <div class="form__group">


                                <input type="password" id="password" class="form__field" placeholder="Password" onChange={e => handleChange('password', e.target.value)} autoComplete="off">
                                </input>
                                <label for="password" class="form__label">Password</label>
                            </div>
                            {required && !password && !hidealert && (
                                <div class="alert alert-danger clm_alert">
                                    <small> Password is required!</small>
                                    <a onClick={() => { handleChange('hidealert', true); }}><span class="material-icons">close</span></a>
                                </div>
                            )}
                        </div>





                        <input class="login_btn" type="button" value="Login" onClick={handleSubmit}></input>
                        <a class="forgotpassword" onClick={openModal} >Forgot Password</a>
                        <a class="signup_btn" onClick={() => { handleChange('uitype', 'signup'); handleChange('required', false); }}>Signup</a>
                    </div>

                }
                else {
                    return <div class="signup_form">
                        <div class="form_logo"><a href="/home"> <img src={appLogo1} /></a> </div>
                        <h3 class="signup_text">Sign Up</h3>
                        <div class="fullname">
                            <div class="form__group ">
                                <input type="text" id="fname" class="form__field" value={firstName} placeholder="Enter Full Name" onChange={e => handleChange('firstName', e.target.value)} autoFocus autoComplete="off">
                                </input>
                                <label for="fname" class="form__label">Enter Full Name</label>
                            </div>
                            {required && !firstName && !hidealert && (
                                <div class="alert alert-danger clm_alert">
                                    <small> Full Name is required!</small>
                                    <a onClick={() => { handleChange('hidealert', true); }}><span class="material-icons">close</span></a>
                                </div>
                            )}
                        </div>

                        <div class="email">
                            <div class="form__group ">
                                <input type="text" id="email" class="form__field" placeholder="Email address" onChange={e => handleChange('emailAddress', e.target.value)} autoComplete="off">
                                </input>
                                <label for="email" class="form__label">Email address</label>
                            </div>
                            {required && !emailAddress && !hidealert && (
                                <div class="alert alert-danger clm_alert">
                                    <small> Email Address is required!</small>
                                    <a onClick={() => { handleChange('hidealert', true); }}><span class="material-icons">close</span></a>
                                </div>
                            )}
                        </div>

                        <div class="username">
                            <div class="form__group ">
                                <input type="text" id="username" class="form__field" placeholder="Prefered User Name" onChange={e => handleChange('userName', e.target.value)} autoComplete="off">
                                </input>
                                <label for="username" class="form__label">Prefered User Name</label>
                            </div>
                            {required && !userName && !hidealert && (
                                <div class="alert alert-danger clm_alert">
                                    <small> Prefered User Name is required!</small>
                                    <a onClick={() => { handleChange('hidealert', true); }}><span class="material-icons">close</span></a>
                                </div>
                            )}
                        </div>

                        <div class="password">
                            <div class="form__group ">

                                <input data-tip data-for="registerTip" type="password" id="signpassword" class="form__field" placeholder="Password" onChange={e => handleChange('password', e.target.value)} autoComplete="off">
                                </input>
                                <ReactTooltip id="registerTip" place="top" effect="solid">
                                    Password  must be at least 8 characters long with uppercase, lowercase , number and special characters
                                </ReactTooltip>
                                <label for="password" class="form__label">Password</label>
                            </div>
                            {required && !password && !hidealert && (
                                <div class="alert alert-danger clm_alert">
                                    <small > Password is required!</small>
                                    <a onClick={() => { handleChange('hidealert', true); }}><span class="material-icons">close</span></a>
                                </div>
                            )}

                            {required && !iscognitoPassword(password) && !hidealert && (
                                <div class="alert alert-danger clm_alert">
                                    <small > Password  Must be at least 8 characters long with upper,lowercase,number and special characters!</small>
                                    <a onClick={() => { handleChange('hidealert', true); }}><span class="material-icons">close</span></a>
                                </div>
                            )}
                        </div>

                        <div class="confirmpassword">
                            <div class="form__group ">
                                <input type="password" id="cnpassword" class="form__field" autocomplete="off" placeholder="Confirm Password" onChange={e => handleChange('confirmPassword', e.target.value)} autoComplete="new-password" >
                                </input>
                                <label for="cnpassword" class="form__label">Confirm Password</label>
                            </div>
                            {required && !confirmPassword && !hidealert && (
                                <div class="alert alert-danger clm_alert">
                                    <small> Confirm Password is required!</small>
                                    <a onClick={() => { handleChange('hidealert', true); }}><span class="material-icons">close</span></a>
                                </div>
                            )}
                            {required && password != confirmPassword && !hidealert && (
                                <div class="alert alert-danger clm_alert">
                                    <small> Password and confirmPassword not Same!</small>
                                    <a onClick={() => { handleChange('hidealert', true); }}><span class="material-icons">close</span></a>
                                </div>
                            )}
                        </div>


                        {/* <label for="file-upload" class="custom-file-upload avatar"> </label> */}
                        <div class="avatar">
                            <Avatar class="custom-file-upload avatar"
                                width={150}
                                height={110}
                                onCrop={onCrop}
                                onClose={onClose}
                                onBeforeFileLoad={onBeforeFileLoad}

                            />
                            {required && !preview && !hidealert && (
                                <div class="alert alert-danger clm_alert">
                                    <small> Avatar is required!</small>
                                    <a onClick={() => { handleChange('hidealert', true); }}><span class="material-icons">close</span></a>
                                </div>
                            )}
                        </div>
                        {/* {preview ? <img src={preview} alt="Preview" /> : null} */}
                        <input class="login_btn" type="submit" value="Sign Up" onClick={handleSignupSubmit}></input>


                        <p className='terms2'><input type="checkbox" id="vehicle1" name="terms" checked={isChecked}
                            onChange={handleOnChange} />
                            <label for="terms"> I'm 18+</label></p>
                        <div>
                            <p className='terms'><input type="checkbox" id="vehicle1" name="terms" checked={isChecked}
                                onChange={handleOnChange} />
                                <label for="terms"> I Agree to the <a href="/terms">Terms of Service</a>  and <a href="/privacy">Privacy Policy</a></label></p>
                            {required && !isChecked && !hidealert && (
                                <div class="alert alert-danger clm_alert">
                                    <small> Please agree for Terms for Service</small>
                                    <a onClick={() => { handleChange('hidealert', true); }}><span class="material-icons">close</span></a>
                                </div>
                            )}
                        </div>
                        {/* <a class="signup_btn" onClick={() => handleChange('uitype', 'login')}>Login</a> */}

                        <input class="signup_btn" type="button" value="Login" onClick={() => { handleChange('uitype', 'login'); handleChange('required', false) }}></input>

                        {/* 
                <input id="file-upload" type="file"> </input>
                <input class="login_btn" type="submit" value="Sign Up"> </input>
 */}
                        <div className='footer'>
                            <p>© Copy Rights 2022 Free Pakoda. All Rights Reserved.</p>

                            <ul>
                                <li><a href="/aboutus"> About Us</a> </li>
                                <li><a href="/terms"> Terms of Service</a> </li>
                                <li> <a href="/privacy">Privacy Policy</a></li>
                            </ul>


                        </div>
                    </div>
                }

            })()}


            {loading ? <div id="loaderInverseWrapper" >
                <Loader backdrop inverse center content="loading..." />
            </div> : null}
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
                shouldCloseOnOverlayClick={false}
            >
                {(function () {
                    if (uitype != 'code') {
                        return <div class="forgotpassword_popup">
                            <div class="modal-dialog modal-dialog modal-lg">
                                <div class="modal-content">
                                    <a class="close popup_close" data-dismiss="modal" aria-label="Close" onClick={closeModal}> <span class="material-icons"> close
                                    </span></a>
                                    <div class="modal-body">
                                        <div class="row">
                                            <div class="col-6">
                                                <img src={youthLogo} alt=""></img>
                                            </div>
                                            <div class="col-6">
                                                <h4>Forgot Password ?</h4>
                                                <form class="error">
                                                    <div class="mb-3">
                                                        {!forgotcodesent ?
                                                            <div>
                                                                <label class="mb-1">Enter Registered Email</label>
                                                                <input autoComplete="off" type="email" class="form-control" placeholder="Email address" onChange={e => handleChange('emailAddress', e.target.value)}  >
                                                                </input>
                                                                {emailError &&
                                                                    <p class="error">No User Found in chellam saab</p>
                                                                }
                                                            </div> : <div>
                                                                <ReactTooltip id="registerTip" place="top" effect="solid">
                                                                    Password  Must be at least 8 characters long with upper,lowercase,number and special characters!
                                                                </ReactTooltip>
                                                                <label class="mb-1">Password</label>
                                                                <input data-tip data-for="registerTip" autoComplete="off" type="password" class="form-control" placeholder="Password" onChange={e => handleChange('password', e.target.value)}  >
                                                                </input>
                                                                <label class="mb-1">Confirm Password</label>
                                                                <input autocomplete="off" type="password" class="form-control" placeholder="Confirm Password" onChange={e => handleChange('confirmPassword', e.target.value)}  >
                                                                </input>
                                                                <label class="mb-1">Code</label>
                                                                <input autocomplete="off" type="text" class="form-control" placeholder="Code" onChange={e => handleChange('code', e.target.value)}  >
                                                                </input>
                                                            </div>}

                                                    </div>
                                                    {forgotcodesent ?
                                                        <div >
                                                            <button type="button" class="btn btn-primary w500" onClick={handlepasswordchange}>Change Password</button>
                                                        </div>

                                                        : <div >
                                                            <button type="button" class="btn btn-primary w500" onClick={handlepasswordcode}>Get Code</button>
                                                        </div>}
                                                    {/* <button type="button" class="btn btn-primary w500">Get Code</button> */}
                                                    {required && !emailAddress && (
                                                        <p class="error">Email Address is required!</p>

                                                    )}
                                                    {required && !password && forgotcodesent && (
                                                        <p class="error"> Password is required!</p>
                                                    )}
                                                    {required && !confirmPassword && forgotcodesent && (
                                                        <p class="error">
                                                            Confirm Password is required!</p>

                                                    )}
                                                    {required && password != confirmPassword && forgotcodesent && (

                                                        <p> Password and confirmPassword not Same!</p>

                                                    )}
                                                    {required && !code && forgotcodesent && (
                                                        <p class="error">Code is required!</p>

                                                    )}
                                                </form>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div >
                    }
                    else {
                        return <div class="forgotpassword_popup">
                            <div class="modal-dialog modal-dialog modal-lg">
                                <div class="modal-content">
                                    <a class="close popup_close" data-dismiss="modal" aria-label="Close" onClick={closeModal}> <span class="material-icons"> close
                                    </span></a>
                                    <div class="modal-body">
                                        <div class="row">
                                            <div class="col-6">
                                                <img src={youthLogo} alt=""></img>
                                            </div>
                                            <div class="col-6">
                                                <h4>Confirm Code !</h4>
                                                <p>{emailAddress}</p>
                                                <form>
                                                    <div class="mb-3">
                                                        <label class="mb-1">Code</label>
                                                        <input autocomplete="off" type="code" class="form-control" placeholder="Code" name="code" onChange={e => handleChange('code', e.target.value)} autoFocus >
                                                        </input>
                                                        {required && !code && (
                                                            <div>
                                                                <small> Code is required!</small>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <button type="button" class="btn btn-primary w500" onClick={handleconfirmSubmit}>Confirm</button>
                                                    <button type="button" class="btn btn-primary w500" onClick={handleresend}>Resend Code</button>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                })()}
            </Modal >


        </div >



    );
}
export default Login





