import React, { useContext, useState, useRef, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { getmemes } from '../api/meme';
import _, { isNull } from "lodash";
import { Loader, Notification, toaster } from 'rsuite';
import trending1 from '../assets/images/trending.svg';
import mymems from '../assets/images/mymemes.svg';
import profilepic from '../assets/images/fplogo.jpg';

const Help = (props) => {
    const {setShowHelp, showHelp, getallmemes, localuserid} = props;
    const useravatar = localStorage.getItem("avatar") || '';

  return (

    <Modal show={showHelp}>
      <Modal.Header closeButton onClick={() => setShowHelp(false)}>
        <Modal.Title>Help</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div class="post_actions">
        <div class="home_profile">
          <a href="/addmeme" class="help_text"> <span className="icon"><img class="help_img" src={trending1} /> </span> Create a new meme by clicking here. </a>
          </div>
          <div class="home_profile">
          <a onClick={() => getallmemes()} class="help_text"> <span className="icon"><img class="help_img" src={trending1} /> </span> See all the Trending memes by clicking here. </a>
          </div>
          <div class="home_profile">
          <a onClick={() => getallmemes(localuserid)} class="help_text"> <span className="icon"><img class="help_img" src={mymems} /> </span> See the memes you have created by clicking here. </a>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={() => setShowHelp(false)}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
export default Help;
