import React, { useContext, useState, useEffect } from "react";

import { AppContext } from '../context/AppContext';

import autoimg from '../assets/images/auto.png';
import appLogo1 from '../assets/images/fplogo.jpg';
import { deleteUser, inviteEmail } from '../api/auth'
import AvatarIcon from '@rsuite/icons/legacy/Avatar';
import { getmemes, upserttweet, upsertcomment, removecomment, updatememe, removelike, upsertlike, removememe, upsertreport, upsertmemes, uploadmemes } from '../api/meme'
import _, { isNull } from "lodash";
import Header from "../elements/header";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { Link, Navigate } from 'react-router-dom';
import moment from "moment";
import { Loader, Notification, toaster, Button, InputPicker, Uploader, Modal as RModal } from 'rsuite';
import {
  EmailShareButton,
  FacebookShareButton,
  HatenaShareButton,
  InstapaperShareButton,
  LineShareButton,
  LinkedinShareButton,
  LivejournalShareButton,
  MailruShareButton,
  OKShareButton,
  PinterestShareButton,
  PocketShareButton,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  ViberShareButton,
  VKShareButton,
  WhatsappShareButton,
  WorkplaceShareButton
} from "react-share";
import { FacebookIcon, TwitterIcon, WhatsappIcon } from "react-share";
import character1 from '../assets/images/Character1.svg';
import character2 from '../assets/images/Character2.svg';
import character3 from '../assets/images/Character3.png';
import profilepic from '../assets/images/fplogo.jpg';
import likeicon from '../assets/images/like_icon.svg';
import attachicon from '../assets/images/camera.png';
import reporticon from '../assets/images/report.png';
import telephoneicon from '../assets/images/telephone_icon.svg';
import inviteicon from '../assets/images/telephone_icon.png';
import trending1 from '../assets/images/trending.svg';
import abouticon from '../assets/images/about.svg';
import deleteicon from '../assets/images/deleteaccount.svg';
import mymems from '../assets/images/mymemes.svg';
import logout from '../assets/images/logout.svg';
import add from '../assets/images/add.svg';
import settings from '../assets/images/settings.svg';
import Modal from 'react-modal';
import { bottom } from "@popperjs/core";
import Help from "./help";

function Home(props) {

  const context = useContext(AppContext);
  let { memeid } = useParams();
  const [state, setState] = useState({

    password: '',
    email: '',
    trendtype: '',
  })

  const {

    password,
    email,
    trendtype


  } = state
  const [showHelp, setShowHelp] = useState(false);

  const handleChange = (key, value) => {
    setState(pre => ({ ...pre, [key]: value }))
  }

  let location = useLocation();
  Modal.setAppElement('body');
  console.log(props.memeid, 'currentmeme');

  const [memes, setmemes] = useState([]);
  const [loading, setloading] = useState(false);
  const [usermemes, setusermemes] = useState([]);
  const [comment, setComment] = useState();
  const [userid, setuserid] = useState();
  const [val, setVal] = useState();
  const isLoggedIn = (localStorage.getItem("isLoggedIn") === 'true');
  const isadmin = (localStorage.getItem("isadmin") === 'true');
  const [trendtypeslist, settrendtypeslist] = useState([]);
  const localuserid = localStorage.getItem("userid");
  const useravatar = localStorage.getItem("avatar") || '';
  const navigate = useNavigate();
  const [modalmeme, setmodalmeme] = useState(null);
  const [modalmemeindex, setmodalmemeindex] = useState(null);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [fileInfo, setFileInfo] = React.useState(null);
  const [fileInfotype, setFileInfotype] = React.useState(null);

  const [currentmeme, setcurrentmeme] = useState({});
  const [currentmemeindex, setcurrentmemeindex] = useState(0);
  const [mdelinviteopen, msetdelinviteopen] = React.useState(false);

  const [mopen, msetOpen] = React.useState(false);
  const mhandleOpen = (meme, index) => { msetOpen(true); setcurrentmeme(meme); setcurrentmemeindex(index); }
  const mhandleClose = () => msetOpen(false);

  const [mdelopen, msetmdelopen] = React.useState(false);
  const mdelhandleOpen = () => { msetmdelopen(true); }
  const mdelhandleClose = () => msetmdelopen(false);
  const [uploading, setUploading] = React.useState(false);
  const mdelinviteOpen = () => { msetdelinviteopen(true); }
  const mdelinviteClose = () => { msetdelinviteopen(false); }

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      padding: '150px',
      marginRight: '-50%',
      background: 'black',
      transform: 'translate(-50%, -50%)',
    }

  };

  const handleClickLogout = () => {

    localStorage.removeItem("userid");
    localStorage.removeItem("token");
    localStorage.removeItem("emailVerified");
    localStorage.removeItem("avatar");
    localStorage.setItem("isLoggedIn", false);
    localStorage.setItem("isadmin", false);
    navigate("/home");

  };

  function openModal(meme, index) {
    setmodalmeme(meme);
    setmodalmemeindex(index);
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  function handletrendChange(value) {
    handleChange("trendtype", value)
    console.log(value);
  }

  async function handletrendsave(meme) {

    checkuserlogin();



    let body = {
      "id": meme._id,
      "trendtype": trendtype
    }

    setloading(true);

    let res = {};
    try {
      res = await updatememe(body);
      if (res.data.statusCode === 200) {



        setloading(false);
        mhandleClose();
        getallmemes();
      }
    } catch (err) {
      mhandleClose();
      setloading(false);

    }



  }

  const [open, setOpen] = React.useState(false);
  const [tweet, settweet] = React.useState('');
  const [backdrop, setBackdrop] = React.useState('static');
  const handleOpen = (meme) => { console.log(meme); setcurrentmeme(meme); setOpen(true); };


  const handleClose = () => setOpen(false);

  useEffect(async () => {

    if (isLoggedIn && localuserid) {
      setuserid(localuserid);

    }
    if (!props.currentMeme) {
      try {
        let allmemes = [];
        if (memeid) {
          console.log(memeid);
          allmemes = await getmemes({ "_id": memeid });

          var trendtypeslist = _.uniq(_.map(allmemes.data.result, 'trendtype'));
          settrendtypeslist(trendtypeslist);
        }
        else {
          allmemes = await getmemes({});

          var trendtypeslist = _.uniq(_.map(allmemes.data.result, 'trendtype'));
          settrendtypeslist(trendtypeslist);
        }
        allmemes = _.orderBy(allmemes.data.result, ['createdat'], ['desc']);
        setmemes(allmemes);
        //  if (!props.currentMeme) { context.setmemeData(allmemes.data.result); }

      }
      catch (err) {

        console.log(err);

      }
    }
    else {
      setmemes([props.currentMeme]);
    }
    // try {
    //   let usermemes = await getmemes({ createduser: userid });

    //   setusermemes(usermemes.data.result);
    // }
    // catch (err) {



    // }

  }, []);

  const previewHelp = () => {
    setShowHelp(true);
  }

  async function getalltrendmemes(trendtype) {
    setloading(true);

    let allmemes = await getmemes({});
    allmemes = _.orderBy(allmemes.data.result, ['createdat'], ['desc']);




    var trendtypeslist = _.uniq(_.map(allmemes, 'trendtype'));
    var usermemes = _.filter(allmemes, function (o) { if (o.trendtype == trendtype) return o })
    setmemes(usermemes);
    settrendtypeslist(trendtypeslist);
    setloading(false);

  }



  async function getallmemes(userid, trendtype) {
    setloading(true);

    let allmemes = await getmemes({});
    allmemes = _.orderBy(allmemes.data.result, ['createdat'], ['desc']);
    if (userid) {

      var usermemes = _.filter(allmemes, function (o) { if (o.createduser._id == userid) return o })

      var trendtypeslist = _.uniq(_.map(usermemes, 'trendtype'));
      settrendtypeslist(trendtypeslist);
      setmemes(usermemes);
      setloading(false);
    }
    else {

      setmemes(allmemes);

      var trendtypeslist = _.uniq(_.map(allmemes, 'trendtype'));
      settrendtypeslist(trendtypeslist);
      setloading(false);
    }
  }


  const updateFieldChanged = index => e => {


    let newArr = [...memes]; // copying the old datas array
    newArr[index].content = e.target.value; // replace e.target.value with whatever you want to change it to
    setmemes(newArr);
    if (!props.currentMeme) { context.setmemeData(newArr); }
  }

  const updateFieldsubcommentChanged = (index, commentindex) => e => {

    let newArr = [...memes];
    newArr[index].comments[commentindex].subcommentcontent = e.target.value;
    setmemes(newArr);
    if (!props.currentMeme) { context.setmemeData(newArr); }
    else {

    }
  }

  function previewFile(file, callback) {

    setFileInfotype(file.type);

    const reader = new FileReader();
    reader.onloadend = () => {
      callback(reader.result);
    };
    reader.readAsDataURL(file);
  }

  const handleImage = (file) => {

    navigate('/addmeme', {
      state: {
        file: file,
      }
    });
  }

  const handleReset = (inputdatatype) => {

    Array.from(document.querySelectorAll("input")).forEach(


      input => {


        if (inputdatatype == input.name) {
          input.value = "";
          return;
        }

      }

    );

  };

  const memeApprove = async (meme, index, status) => {

    //event.preventDefault();
    // console.log(event);
    // console.log(meme);
    checkuserlogin();

    let body = {
      "_id": meme._id,
      isactive: status
    }


    setloading(true);
    let res = {};
    try {
      res = await upsertmemes(body);
      if (res.data.statusCode === 200) {


        let newArr = [...memes]; // copying the old datas array
        newArr[index] = res.data.result; // replace e.target.value with whatever you want to change it to
        setmodalmeme(res.data.result);
        setmemes(newArr);
        if (!props.currentMeme) { context.setmemeData(newArr); }
        setloading(false);
        getallmemes();
      }
    } catch (err) {

      setloading(false);

    }




  };


  const saveComment = async (meme, index, comment, inputdatatype) => {
    checkuserlogin();
    //event.preventDefault();
    // console.log(event);
    // console.log(meme);

    console.log(inputdatatype);
    console.log(comment);
    let body = {
      "_id": meme._id,
      "content": meme.content,


      "status": "new",
      "type": "comment"
    }

    if (comment) {

      body.maincommentid = comment.commentid;
      body.type = 'subcomment';
      body.content = comment.subcommentcontent || '';

    }

    if (body.content == '') {
      Array.from(document.querySelectorAll("input")).forEach(

        input => {


          if (inputdatatype == input.name) {

            body.content = input.value;
            return;
          }

        }

      );

    }

    if (_.isUndefined(body.content)) {
      toaster.push(<Notification closable header={'Error'} type={'error'}>  <span>{'Please Enter Comment'}</span></Notification>, {
        placement: 'topEnd'

      });
      return;
    }
    else if (!_.isUndefined(body.content) && body.content.length == 0) {
      toaster.push(<Notification closable header={'Error'} type={'error'}>  <span>{'Please Enter Comment'}</span></Notification>, {
        placement: 'topEnd'

      });
      return;
    }
    setloading(true);
    let res = {};
    try {
      res = await upsertcomment(body);
      if (res.data.statusCode === 200) {


        let newArr = [...memes]; // copying the old datas array
        newArr[index] = res.data.result; // replace e.target.value with whatever you want to change it to
        setmodalmeme(res.data.result);
        setmemes(newArr);
        if (!props.currentMeme) { context.setmemeData(newArr); }
        handleReset(inputdatatype);
        setloading(false);
        getallmemes();
      }
    } catch (err) {

      setloading(false);

    }




  };


  const savetweet = async (title, desc, isactive) => {
    checkuserlogin();

    var body = {

      "title": tweet,
      "desc": desc,
      "isactive": true

    }

    setloading(true);
    let res = {};

    if (fileInfo) {
      var body = {

        "title": tweet,
        "desc": desc || '',
        "isactive": true,
        "file": fileInfo,
        "fileext": fileInfotype,
        "filetype": fileInfotype.replace("video/", "").replace("image/", "")
      }

      let res = {};
      try {
        res = await uploadmemes(body);
        if (res.data.statusCode === 200) {
          let allmemes = await getmemes({});
          allmemes = _.orderBy(allmemes.data.result, ['createdat'], ['desc']);

          var trendtypeslist = _.uniq(_.map(allmemes, 'trendtype'));
          settrendtypeslist(trendtypeslist);
          setmemes(allmemes);

          setloading(false);

          settweet('');
          setFileInfo(null);
          getallmemes();
        }
      } catch (err) {
        setloading(false);

      }
    }
    else {
      try {
        res = await upserttweet(body);
        if (res.data.statusCode === 200) {

          let allmemes = await getmemes({});
          allmemes = _.orderBy(allmemes.data.result, ['createdat'], ['desc']);

          var trendtypeslist = _.uniq(_.map(allmemes, 'trendtype'));
          settrendtypeslist(trendtypeslist);
          setmemes(allmemes);

          setloading(false);

          settweet('');
          getallmemes();
        }
      } catch (err) {

        setloading(false);

      }
    }




  };

  const removememeComment = async (meme, index, comment, type) => {

    checkuserlogin();

    let body = {
      "_id": meme._id,
      "commentid": comment.commentid,

    }
    if (type == 'subcomment') {
      body.reportindex = getIndexs(meme.report, userid, 'commentid', comment.commentid)
      body.commentsindex = getIndexs(meme.comments, userid, 'commentid', comment.commentid);
      body.likesindex = getIndexs(meme.likes, userid, 'commentid', comment.commentid);
      body.reportindex = getIndexs(meme.report, userid, 'commentid', comment.commentid)
    }
    else {

      body.likesindex = getIndexs(meme.likes, userid, 'commentid', comment.commentid).concat(getIndexs(meme.likes, userid, 'maincommentid', comment.commentid));


      body.commentsindex = getIndexs(meme.comments, userid, 'commentid', comment.commentid).concat(getIndexs(meme.comments, userid, 'maincommentid', comment.commentid));

      body.reportindex = getIndexs(meme.report, userid, 'commentid', comment.commentid).concat(getIndexs(meme.report, userid, 'maincommentid', comment.commentid));
    }

    setloading(true);
    console.log(body);
    let res = {};
    try {
      res = await removecomment(body);
      if (res.data.statusCode === 200) {

        // console.log(index);
        // let newArr = [...memes]; // copying the old datas array
        // newArr[index] = res.data.result; // replace e.target.value with whatever you want to change it to
        // setmodalmeme(res.data.result);
        // setmemes(newArr);
        // if (!props.currentMeme) { context.setmemeData(newArr); }
        console.log(index);
        let newArr = [...memes]; // copying the old datas array
        newArr[index] = res.data.result; // replace e.target.value with whatever you want to change it to
        setmodalmeme(res.data.result);
        setmemes(newArr);
        if (!props.currentMeme) { context.setmemeData(newArr); }
        setloading(false);
        getallmemes();




      }
    } catch (err) {

      setloading(false);

    }




  };

  const checkuserlogin = () => {


    if (!isLoggedIn) {
      toaster.push(<Notification closable header={'Error'} type={'error'}>  <a href="/login"><span>{'Please Login to Continue'}</span></a></Notification>, {
        placement: 'topEnd'

      });
      return;
    }
  }

  const saveLike = async (meme, index, comment, type, maincomment) => {
    checkuserlogin();

    var isactive = _.filter(meme.likes, function (o) { if (o.type == type && o.user._id == userid && o.commentid == comment.commentid) return o });

    if (isactive.length != 0) {


      let body = {
        "_id": meme._id,
        "userid": userid,
        "type": type,
        "likeindex": getIndexs(meme.likes, userid, "commentid", comment.commentid)
      }
      console.log('isactive');
      setloading(true);
      let res = {};
      try {
        res = await removelike(body);
        if (res.data.statusCode === 200) {

          console.log(index);
          let newArr = [...memes]; // copying the old datas array
          newArr[index] = res.data.result; // replace e.target.value with whatever you want to change it to

          setmemes(newArr);
          if (!props.currentMeme) { context.setmemeData(newArr); }
          setloading(false);
          getallmemes();




        }
      } catch (err) {


        setloading(false);
      }
    }

    else {


      let body = {
        "_id": meme._id,


        "status": "new",
        "type": type
      }


      if (comment) {
        body.commentsindex = getIndexs(meme.likes, userid, 'commentid', comment.commentid)

        body.commentid = comment.commentid;
      }
      if (maincomment) {

        body.maincommentid = maincomment.commentid;
      }
      setloading(true);
      let res = {};
      try {
        res = await upsertlike(body);
        if (res.data.statusCode === 200) {

          console.log(index);
          let newArr = [...memes]; // copying the old datas array
          newArr[index] = res.data.result; // replace e.target.value with whatever you want to change it to
          setmodalmeme(res.data.result);
          setmemes(newArr);
          if (!props.currentMeme) { context.setmemeData(newArr); }
          setloading(false);
          getallmemes();




        }
      } catch (err) {


        setloading(false);
      }

    }


  };

  const saveReport = async (meme, index, comment, type, maincomment, value) => {
    checkuserlogin();

    let body = {
      "_id": meme._id,
      "status": "new",
      "type": type,

    }

    if (type == 'memereport' || type == 'memeunreport') {
      var stype = '';
      if (type == 'memereport') { stype = 'memeunreport' } else { stype = 'memereport' }
      body.reportindex = getIndexs(meme.report, userid, 'type', stype)
    }

    if (comment) {

      body.commentid = comment.commentid;
      body.reportindex = getIndexs(meme.report, userid, 'commentid', comment.commentid)
    }
    if (maincomment) {

      body.maincommentid = maincomment.commentid;
    }
    setloading(true);
    console.log(body);
    let res = {};
    try {

      res = await upsertreport(body);
      if (res.data.statusCode === 200) {

        console.log(index);
        let newArr = [...memes]; // copying the old datas array
        newArr[index] = res.data.result; // replace e.target.value with whatever you want to change it to
        setmodalmeme(res.data.result);
        setmemes(newArr);
        if (!props.currentMeme) { context.setmemeData(newArr); }
        setloading(false);
        getallmemes();




      }
    } catch (err) {

      getallmemes();
      setloading(false);
    }




  };

  function getIndexs(data, userid, field, value) {

    var indexes = [], i = -1;
    for (var i = 0; i < data.length; i++) {

      if (userid) {

        if (data[i]['user']['_id'] == userid && data[i][field] == value) {
          indexes.push(i);
        }
      }
      else {


        if (data[i][field] == value) {
          indexes.push(i);
        }
      }

    }

    return indexes;
  }



  const removememeLike = async (meme, index, type) => {

    checkuserlogin();



    let body = {
      "_id": meme._id,
      "userid": userid,
      "type": type,
      "likeindex": getIndexs(meme.likes, userid, "type", type)
    }

    console.log(body);
    setloading(true);
    let res = {};
    try {
      res = await removelike(body);
      if (res.data.statusCode === 200) {

        console.log(index);
        let newArr = [...memes]; // copying the old datas array
        newArr[index] = res.data.result; // replace e.target.value with whatever you want to change it to
        setmodalmeme(res.data.result);
        setmemes(newArr);
        if (!props.currentMeme) { context.setmemeData(newArr); }

        setloading(false);
        getallmemes();



      }

    } catch (err) {
      getallmemes();
      setloading(false);

    }




  };

  const deletememe = async (meme, index, type) => {

    checkuserlogin();



    let body = {
      "_id": meme._id

    }

    setloading(true);

    let res = {};
    try {
      res = await removememe(body);
      if (res.data.statusCode === 200) {
        debugger;

        let newArr = [...memes]; // copying the old datas array
        _.pullAt(newArr, index); // replace e.target.value with whatever you want to change it to
        ;
        setmemes(newArr);
        if (!props.currentMeme) { context.setmemeData(newArr) }
        setloading(false);
        mhandleClose();
        getallmemes();
      }
    } catch (err) {
      mhandleClose();
      setloading(false);

    }




  };


  const deletecurrentuser = async () => {

    checkuserlogin();



    let body = {
      "emailAddress": localStorage.getItem("useremail"),
      "password": password,
      "_id": localuserid

    }

    setloading(true);

    let res = {};
    try {
      res = await deleteUser(body);
      if (res.data.statusCode === 200) {

        setloading(false);
        mdelhandleClose();
        toaster.push(<Notification closable header={'Info'} type={'info'}>  <span>{'You Account is Deactivated and Deleted'}</span></Notification>, {
          placement: 'topEnd'

        });
        localStorage.removeItem("userid");
        localStorage.removeItem("token");
        localStorage.removeItem("emailVerified");
        localStorage.removeItem("avatar");
        localStorage.setItem("isLoggedIn", false);

        navigate("/login");
      }
    } catch (err) {
      mdelhandleClose();
      setloading(false);
      toaster.push(<Notification closable header={'Error'} type={'error'}>  <span>{'Invalid Password'}</span></Notification>, {
        placement: 'topEnd'

      });
    }




  };


  const inviteuser = async () => {

    checkuserlogin();



    let body = {
      "tomail": email


    }

    setloading(true);

    let res = {};
    try {
      res = await inviteEmail(body);
      if (res.data.statusCode === 200) {

        setloading(false);
        handleChange('email', "")
        mdelinviteClose();
        toaster.push(<Notification closable header={'Info'} type={'info'}>  <span>{'You Friend Has Been Invited'}</span></Notification>, {
          placement: 'topEnd'

        });

      }
    } catch (err) {
      mdelinviteClose();
      setloading(false);
      toaster.push(<Notification closable header={'Error'} type={'error'}>  <span>{'Invalid Email'}</span></Notification>, {
        placement: 'topEnd'

      });
    }




  };



  const check = () => {
    //  console.log(userid);
    //console.log(memes[0]);
    var test = _.filter(memes[0].likes, function (o) { if (o.type == 'memelike' && o.user._id == '61ff5933ac8ad93be0302c51') return o })
    // console.log(memes[0].likes, 'memes[0]');
    // console.log(test, 'test');
  }

  return (


    <div class="home">
      {loading ? <div id="loaderInverseWrapper" class="loaderzindex"  >
        <Loader style={{ zIndex: 1 }} backdrop inverse center content="loading..." />
      </div> : null
      }
      {/* <span style={{ color: 'white', left: '10px' }} ><b>Best viewed at 1024 x 768</b></span> */}
      <div class="character1">
        <img src={character1} class="w100" alt=""></img>
      </div>
      <div class="character2">
        <img src={character2} class="w100" alt=""></img>
      </div>
      <div class="character3">
        <img src={character3} class="w100" alt=""></img>
      </div>


      <div class="auto_block">

        {/* <div class="post_comment">
          <input type="text" placeholder="What's Happening!!"
            onChange={e => settweet(e.target.value)}

          />
          <button class="btn" onClick={() => savetweet()}>
            <img src={posticon} /> Post</button>
        </div> */}
        <div class="post_block">
          <div className="post_hed">
            <div></div>
            <div className="post_logo"><a href="/home"> <img src={appLogo1} /></a></div>
            <div className="home_profile">
              {isLoggedIn && (
                <a href="/addmeme" className="hed_link"> <span className="icon"><img src={trending1} /> </span> Create </a>
              )}
              {/* <div class="dropdown">
                    <a class="dropdown-toggle" role="button" id="dropdownMenuLink"
                      data-bs-toggle="dropdown"
                      data-toggle="dropdown" aria-expanded="false">
                     <span className="icon"><img src={trending1} />Trending </span> 
                    </a>

                    <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
             
                    </div>
                  </div> */}
              <div class="dropdown">
                <a class="dropdown-toggle" role="button" id="dropdownMenuLink"
                  data-bs-toggle="dropdown"
                  data-toggle="dropdown" aria-expanded="false"
                  className="hed_link"> <span className="icon"><img src={trending1} /> </span> Trending</a>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">

                  {trendtypeslist.map((member) => (
                    <a onClick={() => getalltrendmemes(member)} class="dropdown-item" >{member}</a>
                  ))}

                </div>
              </div>
              {isLoggedIn && (
                <a onClick={() => getallmemes(localuserid)} className="hed_link"> <span className="icon"><img src={mymems} /> </span> My Memes</a>
              )}
              {!modalIsOpen && (
                <div class=" profile">
                  <div class="dropdown">
                    <a class="dropdown-toggle" role="button" id="dropdownMenuLink"
                      data-bs-toggle="dropdown"
                      data-toggle="dropdown" aria-expanded="false">
                      <img src={useravatar || profilepic} width={39} height={39} />
                    </a>

                    <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                      {/* <a class="dropdown-item" href="#">Profile</a> */}


                      {!isLoggedIn && (
                        <a href="/login" class="dropdown-item"><span class="material-icons">
                          login
                        </span>Login/SignUp</a>
                      )}

                      {isLoggedIn && (
                        <Link to="/changepassword" class="dropdown-item"><span className="icon"><img src={telephoneicon} /> </span>Change Password</Link>
                      )}
                      <Link to="/aboutus" class="dropdown-item"><span className="icon"><img src={telephoneicon} /> </span>About Us</Link>
                      {isLoggedIn && (
                        <a href="javascript:void(0)" class="dropdown-item" onClick={mdelinviteOpen}><span className="icon"><img src={inviteicon} /> </span>Invite Friend</a>
                      )}
                      {isLoggedIn && (
                        <a href="javascript:void(0)" class="dropdown-item" onClick={mdelhandleOpen}><span className="icon"><img src={deleteicon} /> </span>Delete Account</a>
                      )}
                      {isLoggedIn && (
                        <a href="javascript:void(0)" class="dropdown-item" onClick={handleClickLogout}><span className="icon"><img src={logout} /> </span>Logout</a>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="card_wrp">
            <div class="post_comment post_commentut" style={{ height: '20px !important' }}>
              {/* <input type="text" placeholder="What's Happening!!" style={{ height: '20px !important' }}
                onChange={e => settweet(e.target.value)}
                value={tweet}
              /> */}
              <textarea style={{ height: '20px !important' }} placeholder="What's Happening!!" onChange={e => settweet(e.target.value)}
                value={tweet}>

              </textarea>
              <div class="btnattach post_likeblock">
                <div class="like">


                  <Uploader
                    accept="image/*,video/*"
                    fileListVisible={false}

                    draggable
                    multiple={false}
                    onUpload={file => {
                      setUploading(true);
                      previewFile(file.blobFile, value => {
                        setFileInfo(value);
                      
                        setFileInfotype(file.blobFile
                          .type);

                      });
                    }}
                    onSuccess={(response, file) => {
                      setUploading(false);
                    
                    }}
                    onError={() => {
                      console.log('error')
                    
                      setUploading(false);

                    }}
                  >
                    <button style={{ width: 60, height: 60 }}>
                      {uploading && <Loader backdrop center />}
                      {fileInfo ? (
                        <img src={fileInfo} class="img" width="100%" height="100%" />
                       
                      ) : (

                        <img src={attachicon} class="img" />
                      )}
                    </button>
                  </Uploader>





                </div>
              </div>

              <button class="btn" onClick={() => savetweet()}> Post</button>


            </div>

            {memes.map((meme, index) => (
              <div class="post_item" key={meme._id}>
                <div class="post_profile">
                  <div class="profile_pic">
                    <img src={meme?.createduser?.avatar[0] && meme?.createduser?.avatar[0]?.file || appLogo1} />
                  </div>
                  <h3>{meme.createduser.userName}</h3>
                </div>
                {meme.type && meme.type != 'tweet' &&
                  <div class="post_image" onClick={() => openModal(meme, index)}>
                    {/* <img src={meme.meme.file} class="w100" alt="" /> */}

                    {meme?.meme?.filetype && meme?.meme?.fileext.includes("video") ? <video width="100%" height="100%" controls>
                      <source src={meme.meme.file} type={meme.meme.fileext}></source>
                    </video> : <img src={meme.meme.file} class="w100" alt="" />}
                    {/* <video class="w100" controls>
                      <source src="https://www.w3schools.com/html/mov_bbb.mp4" type="video/mp4"></source>
                  
                      Your browser does not support HTML video.
                    </video> */}
                  </div>}
                <div class="post_actions">
                  <div class="post_details">

                    <p class="post_title" onClick={() => openModal(meme, index)}>{meme.title}  </p>
                    {trendtypeslist && trendtypeslist.length != 0 && isadmin && isLoggedIn ?
                      <div>
                        <InputPicker
                          value={meme?.trendtype || "cricket"}
                          data={trendtypeslist.map(item => ({ label: item, value: item }))}
                          onChange={handletrendChange}
                          creatable
                          style={{ width: 224 }}
                        />
                        <span onClick={() => handletrendsave(meme)} class="material-icons active" >add</span></div> : null}
                  </div>

                  <div class="post_likeblock">
                    {moment(meme.createdat).fromNow()}

                    {meme.createduser._id == userid || isadmin && isLoggedIn ?
                      <div class="like">

                        <span onClick={() => mhandleOpen(meme, index)} class="material-icons active" >delete</span>
                        {/* <span onClick={mhandleOpen(meme, index)} class="material-icons active" >delete</span> */}

                      </div>
                      :
                      null
                    }
                    <div class="like">


                      {
                        !_.isUndefined(userid) ?

                          meme.createduser._id != userid ?

                            _.filter(meme.likes, function (o) { if (o.type == 'memelike' && o.user._id == userid) return o }).length != 0 ?
                              <div>

                                <img src={likeicon} class="img" onClick={() => removememeLike(meme, index, 'memelike', null)} />
                                <span class="text" onClick={() => removememeLike(meme, index, 'memelike', null)}>{_.filter(meme.likes, function (o) { if (o.type == 'memelike') return o }).length}  Likes </span>

                              </div>
                              :
                              <div>
                                <img src={likeicon} class="img" onClick={() => saveLike(meme, index, null, 'memelike', null)} />
                                <span class="text" onClick={() => saveLike(meme, index, null, 'memelike', null)}>{_.filter(meme.likes, function (o) { if (o.type == 'memelike') return o }).length} Likes </span>

                              </div>

                            :
                            null
                          :
                          null
                      }




                      {/* 
<a href={modalmeme.meme.file} target="_blank" class="post_icon">
                                <span class="material-icons">  get_app</span>
                              </a>}
                            <a href="#" onClick={() => handleOpen(modalmeme)} class="post_icon">
                              <span class="material-icons"> share</span>
                            </a>
                            <a href="#" class="post_icon">
                              <span class="material-icons">open_in_new</span>
                            </a> */}





                      {
                        !_.isUndefined(userid) ?

                          meme.createduser._id != userid ?

                            _.filter(meme.report, function (o) { if (o.type == 'memereport' && o.user._id == userid) return o }).length != 0 ?
                              <div>
                                <img src={reporticon} onClick={() => saveReport(meme, index, null, 'memeunreport', null, null)} class="img active" />
                                <span class="text active" onClick={() => saveReport(meme, index, null, 'memeunreport', null, null)}>{_.filter(meme.report, function (o) { if (o.type == 'memereport') return o }).length} Report</span>

                                {/* <span class="material-icons active" onClick={() => saveReport(meme, index, null, 'memeunreport', null, null)}> report</span> */}
                              </div>
                              :
                              <div>
                                <img src={reporticon} onClick={() => saveReport(meme, index, null, 'memereport', null,)} class="img" />
                                <span class="text" onClick={() => saveReport(meme, index, null, 'memereport', null,)}>{_.filter(meme.report, function (o) { if (o.type == 'memereport') return o }).length} Report</span>

                                {/* <span class="material-icons" onClick={() => saveReport(meme, index, null, 'memereport', null,)}> report</span> */}
                              </div>

                            :
                            null
                          :
                          null
                      }

                    </div>
                    <div class="comments">
                      <a href={meme.file} target="_blank" >
                        <span class="material-icons">  get_app</span>
                      </a>
                      <a href="#"  >
                        <span class="material-icons" onClick={() => handleOpen(meme)}> share</span>
                      </a>

                      <img src={telephoneicon} class="img" />
                      <a class="text" data-toggle="modal" data-target="#exampleModal" onClick={() => openModal(meme, index)}>{_.filter(meme.comments, function (o) { if (o.type == 'comment') return o }).length} Comments</a>
                    </div>
                  </div>

                </div>
                <div class="post_comment">

                  <textarea placeholder="Add Comments"
                    name={'meme' + index + 'comment'}
                    value={meme.content}
                    onChange={updateFieldChanged(index)}> </textarea>
                  <button class="btn" onClick={() => saveComment(meme, index, null, 'meme' + index + 'comment')}>
                    Post</button>
                </div>
                <div class="comment_details">
                  <div class="comment_lists">
                    {_.filter(meme.comments, function (o) { if (o.type == 'comment') return o }).map((comment, indexcomment) => (

                      <div class="comment_list" key={comment.commentid}>
                        <div class="post_profile">
                          <div class="profile_pic">
                            <img src={comment?.user?.avatar[0]?.file && comment?.user?.avatar[0]?.file || appLogo1} />
                          </div>
                          <div class="post_title">
                            <h3>{comment.user.userName}
                              <span class="comment"> {comment.content}
                                🏏❤️
                              </span>
                            </h3>
                            <div class="comment_actions">
                              <a href="#" class="cmt_act">{moment(comment.uploadedAt).fromNow()}</a>
                              {/* <a href="#" class="cmt_act2">5,736 likes</a> */}
                              {!_.isUndefined(userid) ?

                                <div class="cmt_act2">

                                  {comment.user._id == userid || isadmin && isLoggedIn ?
                                    <span>
                                      <button onClick={() => removememeComment(meme, 0, comment, 'maincomment')}>Delete</button>

                                    </span>
                                    :
                                    null
                                  }
                                  <span class={`material-icons ${_.filter(meme.likes, function (o) { if (o.type == 'commentlike' && o.user._id == userid && o.commentid == comment.commentid) return o }).length != 0 ? "active" : ""}`} onClick={() => saveLike(meme, index, comment, 'commentlike', null)}> thumb_up </span>
                                  <span class={`material-icons ${_.filter(meme.likes, function (o) { if (o.type == 'commentdislike' && o.user._id == userid && o.commentid == comment.commentid) return o }).length != 0 ? "active" : ""}`} onClick={() => saveLike(meme, index, comment, 'commentdislike', null)}>
                                    thumb_down
                                  </span>
                                </div>
                                : null}

                            </div>


                          </div>
                        </div>
                      </div>
                    ))}

                  </div>
                </div>
              </div>
            ))}

          </div>
        </div>

      </div>
      <div className='footer'>
        <p>© Copy Rights 2022 Free Pakoda. All Rights Reserved.</p>
        {/* <ul>
          <li><a href="/aboutus"> About Us</a> </li>
          <li><a href="/terms"> Terms of Service</a> </li>
          <li> <a href="/privacy">Privacy Policy</a></li>
        </ul> */}
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
        shouldCloseOnOverlayClick={false}
      >
        {modalmeme ?
          <div class="comments_popup" id="exampleModal" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <a class="close popup_close" data-dismiss="modal" aria-label="Close" onClick={closeModal}>
              <span class="material-icons"> close
              </span>
            </a>
            <div class="modal-dialog modal-dialog modal-xl">
              <div class="modal-content">

                <div class="modal-body">
                  <div class="comment_wrp">

                    {modalmeme.type && modalmeme.type != 'tweet' ?
                      <div class="post_img_block">
                        {/* <img src={modalmeme.meme.file} class="post_img" alt="" /> */}
                        {modalmeme?.meme?.filetype && modalmeme?.meme?.fileext.includes("video") ? <video width="100%" height="100%" controls>
                          <source src={modalmeme.meme.file} type={modalmeme.meme.fileext}></source>
                        </video> : <img src={modalmeme.meme.file} class="w100" alt="" />}
                      </div>
                      : <div class="post_img_block">
                        {/* <p class="cmt_act" >{modalmeme.title}</p> */}
                        {/* <img src={autoimg} class="post_img" alt="" /> */}
                      </div>
                    }
                    <div class="comment_details">
                      <div class="post_profile">
                        <div class="profile_pic">
                          <img src={modalmeme?.createduser?.avatar[0]?.file && modalmeme?.createduser?.avatar[0]?.file || appLogo1} />
                          <h3>{modalmeme.createduser.userName}</h3>
                        </div>
                        <h3>{modalmeme.title}</h3>
                        {/* <h3>{modalmeme.createduser.userName}</h3> */}
                        <a class="post_options" href="#">
                          <span class="material-icons"> more_horiz </span>
                        </a>
                      </div>
                      <div class="comment_lists">
                        {_.filter(modalmeme.comments, function (o) { if (o.type == 'comment') return o }).map((comment, indexcomment) => (

                          <div class="comment_list" key={comment.commentid}>
                            <div class="post_profile">
                              <div class="profile_pic">
                                <img src={comment?.user?.avatar[0]?.file && comment?.user?.avatar[0]?.file || appLogo1} />
                              </div>
                              <div class="post_title">
                                <h3>{comment.user.userName}
                                  <span class="comment"> {comment.content}
                                    🏏❤️
                                  </span>
                                </h3>
                                <div class="comment_actions">
                                  <a href="#" class="cmt_act">{moment(comment.uploadedAt).fromNow()}</a>
                                  {/* <a href="#" class="cmt_act2">5,736 likes</a> */}
                                  {!_.isUndefined(userid) ?

                                    <div class="cmt_act2">

                                      {comment.user._id == userid || isadmin && isLoggedIn ?
                                        <span>
                                          <button onClick={() => removememeComment(modalmeme, 0, comment, 'maincomment')}>Delete</button>

                                        </span>
                                        :
                                        null
                                      }
                                      <span class={`material-icons ${_.filter(modalmeme.likes, function (o) { if (o.type == 'commentlike' && o.user._id == userid && o.commentid == comment.commentid) return o }).length != 0 ? "active" : ""}`} onClick={() => saveLike(modalmeme, modalmemeindex, comment, 'commentlike', null)}> thumb_up </span>
                                      <span class={`material-icons ${_.filter(modalmeme.likes, function (o) { if (o.type == 'commentdislike' && o.user._id == userid && o.commentid == comment.commentid) return o }).length != 0 ? "active" : ""}`} onClick={() => saveLike(modalmeme, modalmemeindex, comment, 'commentdislike', null)}>
                                        thumb_down
                                      </span>
                                    </div>
                                    : null}

                                </div>
                                {/* <div class="post_comment">
                                  <span class="material-icons">sentiment_satisfied_alt</span>

                                  <input id="inputEmail" type="text" class="comment_input" placeholder=" Reply comment…"   >
                                  </input>
                             
                                  <div class="comment_actions">
                                    <a href="#" class="cmt_act2">Reply</a>

                                  </div>
                                </div>
                                <div class="comment_actions">


                                  <div class="rep_line"></div>
                                  <a href="#" class="cmt_act2">View all replies ({_.filter(modalmeme.comments, function (o) { if (o.type == 'subcomment' && o.maincommentid == comment.commentid) return o }).length})</a>
                                </div> */}

                              </div>
                            </div>
                          </div>
                        ))}

                      </div>
                      <div class="post_share">
                        <div class="icons">
                          <div>
                            <a href="#" class="post_icon">
                              {_.filter(modalmeme.likes, function (o) { if (o.type == 'memelike' && o.user._id == userid) return o }).length != 0 ?


                                <span class="material-icons active" onClick={() => removememeLike(modalmeme, modalmemeindex, 'memelike', null)}>favorite</span> :

                                <span class="material-icons" onClick={() => saveLike(modalmeme, modalmemeindex, null, 'memelike', null)}>favorite_border</span>}
                              {/* <span class="material-icons"> favorite_border </span> */}
                            </a>

                            {modalmeme.type && modalmeme.type != 'tweet' &&
                              <a href={modalmeme.meme.file} target="_blank" class="post_icon">
                                <span class="material-icons">  get_app</span>
                              </a>}
                            <a href="#" onClick={() => handleOpen(modalmeme)} class="post_icon">
                              <span class="material-icons"> share</span>
                            </a>
                            <a href="#" class="post_icon">
                              <span class="material-icons">open_in_new</span>
                            </a>
                          </div>
                          <div>
                            {/* <a href="#" class="post_icon2">
                              <span class="material-icons"> bookmark_border</span>
                            </a> */}
                            {/* <a href="#" class="post_icon2">
                              <span class="material-icons"> report</span>
                            </a> */}

                            {
                              !_.isUndefined(userid) ?

                                modalmeme.createduser._id != userid ?

                                  _.filter(modalmeme.report, function (o) { if (o.type == 'memereport' && o.user._id == userid) return o }).length != 0 ?
                                    <a href="#" class="post_icon2">
                                      <span class="material-icons active" onClick={() => saveReport(modalmeme, modalmemeindex, null, 'memeunreport', null, null)}> report</span>
                                    </a>
                                    :
                                    <a href="#" class="post_icon2">
                                      <span class="material-icons" onClick={() => saveReport(modalmeme, modalmemeindex, null, 'memereport', null,)}> report</span>
                                    </a>

                                  :
                                  null
                                :
                                null
                            }
                          </div>
                        </div>
                        <p>
                          <a href="#">
                            {_.filter(modalmeme.likes, function (o) { if (o.type == 'memelike') return o }).length} likes</a>
                        </p>
                        <div class="comment_actions">
                          {modalmeme.type && modalmeme.type != 'tweet' &&
                            <a href="#" class="cmt_act">{moment(modalmeme.meme.uploadedAt).fromNow()} </a>
                          }
                        </div>
                      </div>
                      <div class="post_comment">
                        <span class="material-icons">sentiment_satisfied_alt</span>
                        <textarea id="inputEmail" type="text"
                          name={'meme' + modalmemeindex + 'comment1'}

                          onChange={updateFieldChanged(modalmemeindex)}
                          class="comment_input" placeholder=" Add a comment…"> </textarea>
                        {/* <input id="inputEmail" type="text"
                          name={'meme' + modalmemeindex + 'comment1'}

                          onChange={updateFieldChanged(modalmemeindex)}
                          class="comment_input" placeholder=" Add a comment…"   >
                        </input> */}
                        <input type="submit" value="Post" class="post_btn" onClick={() => saveComment(modalmeme, modalmemeindex, null, 'meme' + modalmemeindex + 'comment1')}></input>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          : null}
      </Modal >
      <RModal backdrop={backdrop} keyboard={false} open={open} onClose={handleClose}>
        <RModal.Header>
          <RModal.Title>Share meme</RModal.Title>
        </RModal.Header>

        <RModal.Body>
          <div className="post_settings">
            <FacebookShareButton
              url={"https://www.freepakoda.com/" + currentmeme._id}

              hashtag={"#freepakoda"}
              description={currentmeme.title}

            >
              <FacebookIcon size={32} round />
            </FacebookShareButton>

            <TwitterShareButton
              title={currentmeme.title}
              url={"https://www.freepakoda.com/" + currentmeme._id}
              hashtags={["freepakoda"]}
            >
              <TwitterIcon size={32} round />

            </TwitterShareButton>
            <WhatsappShareButton
              url={"http://www.freepakoda.com/" + currentmeme._id}
              title={currentmeme.title}
              separator=":: "

            >
              <WhatsappIcon size={32} round />
            </WhatsappShareButton>
          </div>
        </RModal.Body>

      </RModal>

      <RModal open={mopen} onClose={mhandleClose}>
        <RModal.Header>
          <RModal.Title>Would you like to delete please confirm?</RModal.Title>
        </RModal.Header>
        <RModal.Body>

        </RModal.Body>
        <RModal.Footer>
          <Button onClick={() => deletememe(currentmeme, currentmemeindex)} appearance="primary">
            Yes
          </Button>
          <Button onClick={mhandleClose} appearance="subtle">
            No
          </Button>
        </RModal.Footer>
      </RModal>

      <RModal open={mdelinviteopen} onClose={mdelinviteClose}>
        <RModal.Header>
          <RModal.Title>Would you like to Invite a Friend?</RModal.Title>
        </RModal.Header>
        <RModal.Body>
          <label class="mb-1">Email</label>
          <input data-tip data-for="registerTip" autoComplete="off" type="text" class="form-control"
            placeholder="Email" onChange={e => handleChange('email', e.target.value)}  >
          </input>
        </RModal.Body>
        <RModal.Footer>
          <Button onClick={() => { if (!email) { } else { inviteuser() } }} appearance="primary">
            Yes
          </Button>
          <Button onClick={mdelinviteClose} appearance="subtle">
            No
          </Button>
        </RModal.Footer>
      </RModal>

      <RModal open={mdelopen} onClose={mdelhandleClose}>
        <RModal.Header>
          <RModal.Title>Would you like to delete your Account please confirm?</RModal.Title>
        </RModal.Header>
        <RModal.Body>
          <label class="mb-1">Please Enter Password To Proceed</label>
          <input data-tip data-for="registerTip" autoComplete="off" type="text" class="form-control" placeholder="Password" onChange={e => handleChange('password', e.target.value)}  >
          </input>
        </RModal.Body>
        <RModal.Footer>
          <Button onClick={() => { if (!password) { } else { deletecurrentuser() } }} appearance="primary">
            Yes
          </Button>
          <Button onClick={mdelhandleClose} appearance="subtle">
            No
          </Button>
        </RModal.Footer>
      </RModal>
      {showHelp &&
        <Help
          setShowHelp={setShowHelp}
          showHelp={showHelp}
          getallmemes={getallmemes}
          localuserid={localuserid} />}

    </div >


  );
}

export default Home;
