import React, { useContext, useState, useRef, useEffect } from 'react';

import axios from 'axios';
import { Link, Navigate } from 'react-router-dom';
import { GlobalContext } from "../context/Provider";
import { login } from "../context/actions/Auth";
import { useNavigate, useLocation } from "react-router-dom";
import ImageEditor from "@toast-ui/react-image-editor";
import 'tui-image-editor/dist/tui-image-editor.css'
import { getmemes, uploadmemes } from '../api/meme'
import _ from "lodash";
import Header from "../elements/header";
import moment from "moment";
import { Loader, Notification, toaster } from 'rsuite';
// const Jimp = require("jimp");
import Jimp from "jimp/browser/lib/jimp";
import logo1 from '../assets/images/fplogo.jpg';
const AboutUs = (props) => {




    return (


        <div class="addmeme page">
            {/* <div class="clock"><img src={imgclock} alt="" /></div>
            <div class="plant"><img src={imgplant} alt="" /></div> */}
          
            <div class="content cmspage">
            <Link to="/home" className='content_logo'><img src={logo1} /></Link>
          
            <div class="wrapper">
            <Link to="/home" className='backBtn'><span className="material-icons">
                        arrow_back_ios
                    </span>Back</Link>
                <h1>About Us</h1>
                <p>The desire to express is in human dna. </p>
                <p>  The expression takes different forms right from  cave art to the current social media. </p>
                <p>  freepakoda.com gives its users a new platform to express themselves through micro-blogging and memes. </p>
                <p>  The website draws its inspiration from a famous character in a TV series. In line with that the website a has spy theme in its User Interface (UI). </p>
                <p> Hope you enjoy and like the experience.</p>
                <p>  No mud slinging, no pointing fingers, platform to show others the funny beast inside you. </p>
                <p>   Truly objective social networking with the sole purpose of laughing at oneself.</p>

                <p>   From</p>
                <p>  Team 'Free'pakoda</p>
            </div>
            </div>
        </div>

    );
};
export default AboutUs;
