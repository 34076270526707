import React, { useContext, useState, useEffect, useLayoutEffect } from 'react';
import Avatar from 'react-avatar-edit';
import { signupUser, uploadfile, loginUser, verifyEmail, resendConfirmationCode, forgotpassword, forgotpasswordCode, changepassword } from '../api/auth'
import axios from 'axios';
import { Link, Navigate } from 'react-router-dom';
import appLogo from '../assets/images/fplogo.jpg';
import imgclock from '../assets/images/clock.png';
import imgplant from '../assets/images/plant.png';
import youthLogo from '../assets/images/forgotPassword_chellam.svg';
import { AppContext } from '../context/AppContext';
import { login } from "../context/actions/Auth";
import { useNavigate, useLocation } from "react-router-dom";
import _, { isNull } from "lodash";
import { Loader, Notification, toaster } from 'rsuite';
import Modal from 'react-modal';
// import 'rsuite/dist/rsuite.min.css'
// import default style

const ChangePassword = (props) => {
    
    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            padding: '360px',
            marginRight: '-50%',
            background: 'rgba(0,0,0,0.5)',
            transform: 'translate(-50%, -50%)',
        }

    };
    const context = useContext(AppContext);
    // console.log(context, 'intial');
    // if (!context.userData) {
    //     context.setUserData('test');
    //     console.log(context, 'after');
    // }
    let location = useLocation();
    console.log(location);
    let frompage = location.state?.from?.pathname;

    const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }
    const navigate = useNavigate();


    const [preview, setpreview] = useState(null);



    const [state, setState] = useState({
        firstName: '',
        lastName: '',
        emailAddress: '',
        userName: '',
        oldpassword: '',
        newPassword: '',
        confirmPassword: '',
        loading: false,
        uitype: 'login',
        required: false,
        code: '',
        hidealert: true,
        forgotcodesent: false,
    })

    const {
        firstName,
        lastName,
        emailAddress,
        userName,
        oldpassword,
        newPassword,
        confirmPassword,
        loading,
        uitype,
        required,
        code,
        hidealert,
        forgotcodesent
    } = state

    const disabled =

        !emailAddress ||

        !oldpassword ||
        !newPassword 


    const disabledsignup =

        !emailAddress ||
        !firstName ||

        !userName ||
        !preview
    const disabledcode =

        !emailAddress ||

        !code


    const handleChange = (key, value) => {
        setState(pre => ({ ...pre, [key]: value }))
    }

    const handleSignupSubmit = async event => {

        // if (required && !emailAddress) {

        //     toaster.push(<Notification closable header={'Error'} type={'error'}>  <small>{'Email Address is required!'}</small></Notification>, {
        //         placement: 'topEnd'

        //     });

        // }

        // if (required && !firstName) {


        //     toaster.push(<Notification closable header={'Error'} type={'error'}>  <small>{'Name is required!'}</small></Notification>, {
        //         placement: 'topEnd'

        //     });
        // }

        // if (required && !userName) {


        //     toaster.push(<Notification closable header={'Error'} type={'error'}>  <small>{'Prefered User Name is required!'}</small></Notification>, {
        //         placement: 'topEnd'

        //     });
        // }

        // if (required && !confirmPassword) {


        //     toaster.push(<Notification closable header={'Error'} type={'error'}>  <small>{' Confirm Password is required!'}</small></Notification>, {
        //         placement: 'topEnd'

        //     });
        // }
        // if (required && !password) {
        //     toaster.push(<Notification closable header={'Error'} type={'error'}>  <small>{'Password is required!'}</small></Notification>, {
        //         placement: 'topEnd'

        //     });
        // }


        if (disabledsignup) {
            handleChange('required', true)
            handleChange('hidealert', false)
            return
        }
        handleChange('hidealert', true)
        handleChange('required', false)
        handleChange('loading', true)

        const payload = {


            emailAddress,

            oldpassword,
            newPassword,
            firstName,
            lastName,
            userName

        }

        payload.avatar = preview;



        let res = {};
        try {
            res = await signupUser(payload);
            console.log(res);
            if (res.data.statusCode === 200) {

                localStorage.setItem('userid', res.data.result._id);
                localStorage.setItem('avatar', res.data.result.avatar[0].file);

            }
            context.setUserData(res.data.result);
            handleChange('loading', false)
            handleChange('uitype', 'code')
            openModal();
        } catch (err) {

            handleChange('loading', false)
            toaster.push(<Notification closable header={'Error'} type={'error'}>  <span>{err.message}</span></Notification>, {
                placement: 'topEnd'

            });


        }






    };
    const handleconfirmSubmit = async event => {


        if (disabledcode) {
            handleChange('required', true)
            return
        }

        handleChange('required', false)
        handleChange('loading', true)

        const payload = {


            emailAddress,

            code
        }

        payload._id = localStorage.getItem("userid") || '';


        let res = {};
        try {
            res = await verifyEmail(payload);
            if (res.data.statusCode === 200) {
                toaster.push(<Notification closable header={'Verified'} type={'info'}>  <span>{'Email Verified'}</span></Notification>, {
                    placement: 'topEnd'

                });
                localStorage.setItem('emailVerified', true);
                handleChange('loading', false)
                handleChange('uitype', 'login')
                closeModal();
            }
        } catch (err) {

            handleChange('loading', false)
            toaster.push(<Notification closable header={'Error'} type={'error'}>  <span>{err.message}</span></Notification>, {
                placement: 'topEnd'

            });


        }






    };
    const handleresend = async event => {
        event.preventDefault();

        if (!emailAddress) {
            handleChange('required', true)
            return
        }

        handleChange('required', false)
        handleChange('loading', true)

        const payload = {


            emailAddress

        }





        let res = {};
        try {
            res = await resendConfirmationCode(payload);
            if (res.data.statusCode === 200) {
                toaster.push(<Notification closable header={'Code'} type={'info'}>  <span>{'Code Sent'}</span></Notification>, {
                    placement: 'topEnd'

                });
                handleChange('loading', false)

            }
        } catch (err) {

            handleChange('loading', false)
            toaster.push(<Notification closable header={'Error'} type={'error'}>  <span>{err.message}</span></Notification>, {
                placement: 'topEnd'

            });


        }






    };


    const handlepasswordcode = async event => {


        if (!emailAddress) {
            handleChange('required', true)
            return
        }

        handleChange('required', false)
        handleChange('loading', true)

        const payload = {


            emailAddress

        }





        try {
            await forgotpasswordCode(payload).then((res) => {
                if (res.data.statusCode === 200) {
                    toaster.push(<Notification closable header={'Code'} type={'info'}>  <span>{'Code Sent'}</span></Notification>, {
                        placement: 'topEnd'

                    });
                    handleChange('loading', false)
                    handleChange('forgotcodesent', true);
                } else {
                    toaster.push(<Notification closable header={'Code'} type={'info'}>  <span>{'No User Found in chellam saab'}</span></Notification>, {
                        placement: 'topEnd'

                    });
                    handleChange('loading', false)
                }
            });

        } catch (err) {

            handleChange('loading', false)
            toaster.push(<Notification closable header={'Error'} type={'error'}>  <span>{err.message}</span></Notification>, {
                placement: 'topEnd'

            });


        }






    };

    const handlepasswordchange = async event => {
        handleChange('required', false)
        handleChange('loading', true)

        const payload = {


            emailAddress


        }

        payload.password = oldpassword;
        payload.newpassword = newPassword;

        let res = {};
        try {
            res = await changepassword(payload);
            if (res.data.statusCode === 200) {
                toaster.push(<Notification closable header={'Please Login'} type={'info'}>  <span>{'Password Changed Please Login'}</span></Notification>, {
                    placement: 'topEnd'

                });
                handleChange('loading', false)
                handleChange('uitype', 'login')
                closeModal();
            }
        } catch (err) {

            handleChange('loading', false)
            toaster.push(<Notification closable header={'Error'} type={'error'}>  <span>{err.message}</span></Notification>, {
                placement: 'topEnd'

            });


        }






    };



    const onClose = () => {
        setpreview(null)

    }

    const onCrop = (preview) => {
        setpreview(preview);


    }

    return (

        <div class="login_bg">
            <div class="clock"><img src={imgclock} alt="" /></div>
            <div class="plant"><img src={imgplant} alt="" /></div>

            {loading ? <div id="loaderInverseWrapper" >
                <Loader backdrop inverse center content="loading..." />
            </div> : null}
            <div class="forgotpassword_popup">
                <div class="modal-dialog modal-dialog modal-lg">
                    <div class="modal-content">
                        <a class="close popup_close" data-dismiss="modal" aria-label="Close" onClick={() => navigate(-1)}> <span class="material-icons"> close
                        </span></a>
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-6">
                                    <img src={youthLogo} alt=""></img>
                                </div>
                                <div class="col-6">
                                    <h4>Change Password</h4>
                                    <form class="error">
                                        <div class="mb-3">

                                            <div>
                                                <input type="text" class="form-control" placeholder="Old Password" onChange={e => handleChange('oldpassword', e.target.value)}  >
                                                </input>
                                                {required && !oldpassword && (
                                                    <p class="error">Old Password is required!</p>
                                                )}
                                                <input type="text" class="form-control" placeholder="New Password" onChange={e => handleChange('newPassword', e.target.value)}  >
                                                </input>
                                                {required && !newPassword && (
                                                    <p class="error">
                                                        New Password is required!</p>

                                                )}
                                                <input type="text" class="form-control" placeholder="Confirm Password" onChange={e => handleChange('confirmPassword', e.target.value)}  >
                                                </input>
                                                {required && !confirmPassword && (
                                                    <p class="error">
                                                        Confirm Password is required!</p>

                                                )}
                                            </div>

                                        </div>
                                        <div >
                                            <button type="button" class="btn btn-primary w500" onClick={handlepasswordchange}>Change Password</button>
                                        </div>


                                        {/* <button type="button" class="btn btn-primary w500">Get Code</button> */}



                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >

        </div >



    );
}
export default ChangePassword





