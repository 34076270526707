import React, { useContext, useState, useEffect, useLayoutEffect } from 'react';
import Avatar from 'react-avatar-edit';
import { signupUser, uploadfile, loginUser, verifyEmail, resendConfirmationCode, forgotpassword, forgotpasswordCode } from '../api/auth'
import axios from 'axios';
import { Link, Navigate } from 'react-router-dom';
import youthLogo from '../assets/images/youthGraphics.png';
import { AppContext } from '../context/AppContext';
import { login } from "../context/actions/Auth";
import { useNavigate, useLocation } from "react-router-dom";
import _, { isNull } from "lodash";
import { Loader, Notification, toaster } from 'rsuite';

// import 'rsuite/dist/rsuite.min.css'

// import default style

const LoginOld = (props) => {
    const context = useContext(AppContext);
    // console.log(context, 'intial');
    // if (!context.userData) {
    //     context.setUserData('test');
    //     console.log(context, 'after');
    // }
    let location = useLocation();
    console.log(location);
    let frompage = location.state?.from?.pathname;


    const navigate = useNavigate();



    const [preview, setpreview] = useState(null);



    const [state, setState] = useState({
        firstName: '',
        lastName: '',
        emailAddress: '',
        userName: '',
        password: '',
        confirmPassword: '',
        loading: false,
        type: 'login',
        required: false,
        code: '',

        forgotcodesent: false,
    })

    const {
        firstName,
        lastName,
        emailAddress,
        userName,
        password,
        confirmPassword,
        loading,
        type,
        required,
        code,

        forgotcodesent
    } = state

    const disabled =

        !emailAddress ||

        !password


    const disabledsignup =

        !emailAddress ||

        !password ||
        !confirmPassword ||
        !firstName ||
        !lastName ||
        !userName ||
        !preview
    const disabledcode =

        !emailAddress ||

        !code

    const disabledpasswordcode =
        !emailAddress ||
        !password ||
        !confirmPassword ||
        !code




    const handleChange = (key, value) => {
        setState(pre => ({ ...pre, [key]: value }))
    }




    const handleSubmit = async event => {

        event.preventDefault();





        if (disabled) {
            handleChange('required', true)
            return
        }

        handleChange('required', false)
        handleChange('loading', true)

        const payload = {


            emailAddress,

            password

        }






        let res = {};
        try {
            res = await loginUser(payload);
            if (res.data.statusCode === 200) {


                handleChange('loading', false)
                console.log(res.data.result.user.emailVerified);
                localStorage.setItem('userid', res.data.result.user._id);
                localStorage.setItem('token', res.data.result.token);
                localStorage.setItem('isLoggedIn', true);
                localStorage.setItem('avatar', res.data.result.user.avatar[0].file);
                context.setUserData(res.data.result.user);
                if (!res.data.result.user.emailVerified) {
                    localStorage.setItem('emailVerified', res.data.result.user.emailVerified);
                    handleChange('type', 'code')
                }
                else {
                    if (frompage) { navigate(frompage); }
                    else { navigate("/home"); }

                }

            }
        } catch (err) {

            handleChange('loading', false)
            toaster.push(<Notification closable header={'Error'} type={'error'}>  <span>{err.message}</span></Notification>, {
                placement: 'topEnd'

            });


        }






    };

    const handleSignupSubmit = async event => {
        event.preventDefault();

        if (disabledsignup) {
            handleChange('required', true)
            return
        }

        handleChange('required', false)
        handleChange('loading', true)

        const payload = {


            emailAddress,

            password,
            firstName,
            lastName,
            userName

        }

        payload.avatar = preview;



        let res = {};
        try {
            res = await signupUser(payload);
            console.log(res);
            if (res.data.statusCode === 200) {

                localStorage.setItem('userid', res.data.result._id);
                localStorage.setItem('avatar', res.data.result.avatar[0].file);

            }
            context.setUserData(res.data.result);
            handleChange('loading', false)
            handleChange('type', 'code')
        } catch (err) {

            handleChange('loading', false)
            toaster.push(<Notification closable header={'Error'} type={'error'}>  <span>{err.message}</span></Notification>, {
                placement: 'topEnd'

            });


        }






    };
    const handleconfirmSubmit = async event => {
        event.preventDefault();

        if (disabledcode) {
            handleChange('required', true)
            return
        }

        handleChange('required', false)
        handleChange('loading', true)

        const payload = {


            emailAddress,

            code
        }

        payload._id = localStorage.getItem("userid") || '';


        let res = {};
        try {
            res = await verifyEmail(payload);
            if (res.data.statusCode === 200) {
                toaster.push(<Notification closable header={'Verified'} type={'info'}>  <span>{'Email Verified'}</span></Notification>, {
                    placement: 'topEnd'

                });
                localStorage.setItem('emailVerified', true);
                handleChange('loading', false)
                handleChange('type', 'login')
            }
        } catch (err) {

            handleChange('loading', false)
            toaster.push(<Notification closable header={'Error'} type={'error'}>  <span>{err.message}</span></Notification>, {
                placement: 'topEnd'

            });


        }






    };
    const handleresend = async event => {
        event.preventDefault();

        if (!emailAddress) {
            handleChange('required', true)
            return
        }

        handleChange('required', false)
        handleChange('loading', true)

        const payload = {


            emailAddress

        }





        let res = {};
        try {
            res = await resendConfirmationCode(payload);
            if (res.data.statusCode === 200) {
                toaster.push(<Notification closable header={'Code'} type={'info'}>  <span>{'Code Sent'}</span></Notification>, {
                    placement: 'topEnd'

                });
                handleChange('loading', false)

            }
        } catch (err) {

            handleChange('loading', false)
            toaster.push(<Notification closable header={'Error'} type={'error'}>  <span>{err.message}</span></Notification>, {
                placement: 'topEnd'

            });


        }






    };


    const handlepasswordcode = async event => {
        event.preventDefault();

        if (!emailAddress) {
            handleChange('required', true)
            return
        }

        handleChange('required', false)
        handleChange('loading', true)

        const payload = {


            emailAddress

        }





        let res = {};
        try {
            res = await forgotpasswordCode(payload);
            if (res.data.statusCode === 200) {
                toaster.push(<Notification closable header={'Code'} type={'info'}>  <span>{'Code Sent'}</span></Notification>, {
                    placement: 'topEnd'

                });
                handleChange('loading', false)
                handleChange('forgotcodesent', true);
            }
        } catch (err) {

            handleChange('loading', false)
            toaster.push(<Notification closable header={'Error'} type={'error'}>  <span>{err.message}</span></Notification>, {
                placement: 'topEnd'

            });


        }






    };

    const handlepasswordchange = async event => {
        event.preventDefault();

        if (disabledpasswordcode) {
            handleChange('required', true)
            return;
        }

        handleChange('required', false)
        handleChange('loading', true)

        const payload = {


            emailAddress


        }

        payload.verificationCode = code;
        payload.newPassword = password;


        let res = {};
        try {
            res = await forgotpassword(payload);
            if (res.data.statusCode === 200) {
                toaster.push(<Notification closable header={'Please Login'} type={'info'}>  <span>{'Password Changed Please Login'}</span></Notification>, {
                    placement: 'topEnd'

                });
                handleChange('loading', false)
                handleChange('type', 'login')

            }
        } catch (err) {

            handleChange('loading', false)
            toaster.push(<Notification closable header={'Error'} type={'error'}>  <span>{err.message}</span></Notification>, {
                placement: 'topEnd'

            });


        }






    };



    const onClose = () => {
        setpreview(null)

    }

    const onCrop = (preview) => {
        setpreview(preview);


    }

    const onBeforeFileLoad = (elem) => {

        const size =
            (elem.target.files[0].size / 1024 / 1024).toFixed(2);

        if (size > 2) {

            toaster.push(<Notification closable header={'Error'} type={'error'}>  <span>{"Avatar must be less than the size of 2MB"}</span></Notification>, {
                placement: 'topEnd'

            });
            elem.target.files[0] = null;
        }




    }


    return (


        <div class="login_page">


            <div class="login_wrapper">
                <div class="login_header">
                    <img  class="post_logo" alt="" />

                    {(function () {
                        if (type == 'login') {
                            return <button class="common_button" onClick={() => {

                                handleChange('type', 'signup')
                                handleChange('forgotcodesent', false);
                            }

                            }>Sign Up</button>

                        } else if (type == 'signup' || type == 'forgot') {
                            return <button class="common_button" onClick={() => {
                                handleChange('type', 'login'); handleChange('forgotcodesent', false);


                            }}>Login</button>
                        }
                        else {


                        }

                    })()}




                </div>
                <div class="login_block">
                    <div class="graphic_block">
                        <img src={youthLogo} class="graphics" />
                    </div>
                    {(function () {
                        if (type == 'login') {

                            return <form class="form_block" onSubmit={handleSubmit}>
                                <div class="login_form">
                                    {frompage ? <h4>Please Login to Continue</h4>
                                        : <h4>Login</h4>
                                    }

                                    <div class="form-group">
                                        <label>Email</label>
                                        <input id="inputEmail" type="email" class="form-control" placeholder="Email address" type="text" name="emailAddress" onChange={e => handleChange('emailAddress', e.target.value)} autoFocus >
                                        </input>
                                        {required && !emailAddress && (
                                            <div>
                                                <small> Email Address is required!</small>
                                            </div>
                                        )}
                                    </div>
                                    <div class="form-group">
                                        <label>Password</label>

                                        <input id="inputPassword" type="password" class="form-control" placeholder="Password" name="password" onChange={e => handleChange('password', e.target.value)} autoFocus >
                                        </input>
                                        {required && !password && (
                                            <div>
                                                <small> Password is required!</small>
                                            </div>
                                        )}
                                    </div>
                                    <div class="form-group">
                                        <button class="common_button form-control mt-4" type="submit">    {loading ? <Loader /> : 'Login'}</button>
                                        <button class="common_button form-control mt-4" onClick={() => handleChange('type', 'forgot')}>     Forgot Password</button>

                                    </div>
                                </div>
                            </form>
                        } else if (type == 'signup') {
                            return <form class="form_block" onSubmit={handleSignupSubmit}>
                                <div class="login_form">
                                    <h4>Sign Up</h4>
                                    <div class="form-group">
                                        <label>Email</label>
                                        <input id="inputEmail" type="email" class="form-control" placeholder="Email address" name="emailAddress" onChange={e => handleChange('emailAddress', e.target.value)} autoFocus >
                                        </input>
                                        {required && !emailAddress && (
                                            <div>
                                                <small> Email Address is required!</small>
                                            </div>
                                        )}
                                    </div>
                                    <div class="form-group">
                                        <label>First Name</label>
                                        <input id="inputfirstName" class="form-control" placeholder="First Name" type="text" name="firstName" onChange={e => handleChange('firstName', e.target.value)} autoFocus >
                                        </input>
                                        {required && !firstName && (
                                            <div>
                                                <small> FirstName is required!</small>
                                            </div>
                                        )}
                                    </div>
                                    <div class="form-group">
                                        <label>Last Name</label>
                                        <input id="inputlastName" class="form-control" placeholder="Last Name" type="text" name="lastName" onChange={e => handleChange('lastName', e.target.value)} autoFocus >
                                        </input>
                                        {required && !lastName && (
                                            <div>
                                                <small> Last Name is required!</small>
                                            </div>
                                        )}
                                    </div>
                                    <div class="form-group">
                                        <label>Prefered User Name</label>
                                        <input id="inputuserName" class="form-control" placeholder="User Name" type="text" name="userName" onChange={e => handleChange('userName', e.target.value)} autoFocus >
                                        </input>
                                        {required && !userName && (
                                            <div>
                                                <small> Prefered User Name is required!</small>
                                            </div>
                                        )}
                                    </div>
                                    <div class="form-group">
                                        <label>Password</label>

                                        <input id="inputPassword" type="password" class="form-control" placeholder="Password" name="password" onChange={e => handleChange('password', e.target.value)} autoFocus >
                                        </input>
                                        {required && !password && (
                                            <div>
                                                <small> Password is required!</small>
                                            </div>
                                        )}
                                    </div>
                                    <div class="form-group">
                                        <label>Confirm Password</label>

                                        <input id="inputconfirmPassword" type="password" class="form-control" placeholder="Password" name="confirmPassword" onChange={e => handleChange('confirmPassword', e.target.value)} autoFocus >
                                        </input>
                                        {required && !confirmPassword && (
                                            <div>
                                                <small> Confirm Password is required!</small>
                                            </div>
                                        )}
                                    </div>

                                    <div class="form-group">
                                        <label>Avatar</label>
                                        <Avatar
                                            width={200}
                                            height={150}
                                            onCrop={onCrop}
                                            onClose={onClose}
                                            onBeforeFileLoad={onBeforeFileLoad}

                                        />
                                        {required && !preview && (
                                            <div>
                                                <small> Avatar is required!</small>
                                            </div>
                                        )}
                                        {preview ? <img src={preview} alt="Preview" /> : null}

                                    </div>
                                    <div class="form-group">
                                        <button class="common_button form-control mt-4" type="submit">    {loading ? <Loader /> : 'Sign Up'}</button>
                                    </div>

                                </div>
                            </form>
                        }
                        else if (type == 'code') {
                            return <form class="form_block" onSubmit={handleconfirmSubmit}>
                                <div class="login_form">
                                    <h4>Confirm Code</h4>
                                    <div class="form-group">
                                        <p>{emailAddress}</p>
                                        <label>Code</label>
                                        <input id="inputEmail" class="form-control" placeholder="code" type="text" name="code" onChange={e => handleChange('code', e.target.value)} autoFocus >
                                        </input>
                                        {required && !code && (
                                            <div>
                                                <small> Code is required!</small>
                                            </div>
                                        )}
                                    </div>

                                    <div class="form-group">
                                        <button class="common_button form-control mt-4" type="submit">    {loading ? <Loader /> : 'Confirm'}</button>
                                    </div>
                                    <div class="form-group">
                                        <button class="common_button form-control mt-4" onClick={handleresend}>    {loading ? <Loader /> : 'Resend Code'}</button>
                                    </div>
                                </div>
                            </form>

                        }
                        else {
                            return <form class="form_block" >
                                <div class="login_form">
                                    <h4>Forgot Password</h4>

                                    {!forgotcodesent ?
                                        <div class="form-group">

                                            <label>Email</label>
                                            <input id="inputEmail" type="email" class="form-control" placeholder="Email address" type="text" name="emailAddress" onChange={e => handleChange('emailAddress', e.target.value)} autoFocus >
                                            </input>
                                            {required && !emailAddress && (
                                                <div>
                                                    <small> Email Address is required!</small>
                                                </div>
                                            )}
                                        </div> : null}
                                    {forgotcodesent ?

                                        <div class="form-group">
                                            <label>{emailAddress}</label> <br />
                                            <label>Password</label>

                                            <input id="inputPassword" type="password" class="form-control" placeholder="Password" name="password" onChange={e => handleChange('password', e.target.value)} autoFocus >
                                            </input>
                                            {required && !password && (
                                                <div>
                                                    <small> Password is required!</small>
                                                </div>
                                            )}
                                        </div> : null}
                                    {forgotcodesent ? <div class="form-group">
                                        <label>Confirm Password</label>

                                        <input id="inputconfirmPassword" type="password" class="form-control" placeholder="Password" name="confirmPassword" onChange={e => handleChange('confirmPassword', e.target.value)} autoFocus >
                                        </input>
                                        {required && !confirmPassword && (
                                            <div>
                                                <small> Confirm Password is required!</small>
                                            </div>
                                        )}
                                    </div> : null}
                                    {forgotcodesent ? <div class="form-group">

                                        <label>Code</label>
                                        <input id="inputEmail" class="form-control" placeholder="code" type="text" name="code" onChange={e => handleChange('code', e.target.value)} autoFocus >
                                        </input>
                                        {required && !code && (
                                            <div>
                                                <small> Code is required!</small>
                                            </div>
                                        )}
                                    </div> : null}
                                    {forgotcodesent ?
                                        <div class="form-group">
                                            <button class="common_button form-control mt-4" onClick={handlepasswordchange}>    {loading ? <Loader /> : 'Change Password'}</button>
                                        </div>

                                        : <div class="form-group">
                                            <button class="common_button form-control mt-4" onClick={handlepasswordcode}>    {loading ? <Loader /> : 'Get Code'}</button>
                                        </div>}
                                </div>
                            </form>
                        }

                    })()}


                </div>
            </div>

            {loading ? <div id="loaderInverseWrapper" >
                <Loader backdrop inverse center content="loading..." />
            </div> : null}



        </div>

    );


}

export default LoginOld





