import React, { useState, useCallback, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Header from "../elements/header";
// import '../assets/css/gallery.css';
import Home from "./home";
import _, { isNull } from "lodash";
import { AppContext } from '../context/AppContext';
import { FacebookIcon, TwitterIcon } from "react-share";
import { BsFillHeartFill } from 'react-icons/bs';
import { FaComments } from 'react-icons/fa';
import { Drawer, Button } from 'rsuite';
import { getmemes } from '../api/meme'

function Trend() {
    const context = useContext(AppContext);
    const [currentMeme, setcurrentMeme] = useState(0);
    const [photos, setphotos] = useState(_.orderBy(context.memeData, ['trend'], ['desc']));
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);

    const openDrawer = useCallback((event, { photo, index }) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    }, []);

    const closeDrawer = async () => {
        setOpen(false);
        let allmemes = await getmemes({});

        // setphotos(allmemes.data.result);


        let sorted = _.orderBy(allmemes.data.result, ['trend'], ['desc']);

        setphotos(sorted);
        // setCurrentImage(0);
        // setViewerIsOpen(false);
    };



    const handleMeme = (id) => {

        setcurrentMeme(id);
        setOpen(true)

    }


    return (
        <div class="wrapper">
            <Header />
            <img  class="post_logo" alt="" />
            <div className='image-grid'>
                {photos.map((image) => (
                    // <img img src={image.src} alt={image.src} ></img>

                    <div class="container" href="#" onClick={() => handleMeme(image)}>

                        <div class="like-button" title="Like Button">
                            <p>{image.title}</p>
                            {/* <FacebookIcon size={24} round />
                            <FacebookIcon size={24} round /> */}


                            <div style={{ position: "relative" }}>
                                <BsFillHeartFill size={12} round style={{ color: "red" }} />
                                <span style={{ position: "absolute", fontSize: "12px" }}> {_.filter(image.likes, function (o) { if (o.type == 'memelike') return o }).length}</span>
                            </div>
                            <div style={{ position: "relative" }}>
                                <FaComments size={16} round style={{ color: "blue" }} />
                                <span style={{ position: "absolute", fontSize: "16px" }}> {_.filter(image.comments, function (o) { if (o.type == 'comment') return o }).length}</span>
                            </div>

                            {/* <div style={{ position: "relative" }}>
                                <FaBeer size={24} round />
                                <span style={{ position: "absolute", fontSize: "20px" }}>2</span>
                            </div> */}
                        </div>

                        <img src={image.meme.file}
                            class="image"></img>

                        {/* <div class="overlay">
                            <button href="#" class="play-icon" title="Video Play">
                                <FacebookIcon size={32} round />

                            </button>
                        </div> */}

                    </div>
                ))}
            </div>


            <Drawer full backdrop={'static'} open={open} onClose={closeDrawer}>
                <Drawer.Header>

                    <Drawer.Title>{currentMeme.title}</Drawer.Title>
                    <Drawer.Actions>

                        <Button onClick={() => setOpen(false)}>Cancel</Button>
                        {/* <Button onClick={() => setOpen(false)} appearance="primary">
                            Confirm
                        </Button> */}
                    </Drawer.Actions>
                </Drawer.Header>
                <Drawer.Body>
                    <img  class="post_logo" alt="" />
                    <div class="wrapper">
                        <Home currentMeme={currentMeme} />
                    </div>
                </Drawer.Body>
            </Drawer>

        </div >
    );
}

export default Trend;
