import React, { useContext, useState, useEffect } from "react";

import { AppContext } from '../context/AppContext';


import autoimg from '../assets/images/auto.png';
import appLogo1 from '../assets/images/fplogo.jpg';
import trending1 from '../assets/images/trending.svg';
import logout from '../assets/images/logout.svg';
import add from '../assets/images/add.svg';
import settings from '../assets/images/settings.svg';
import { getmemes, upserttweet, upsertcomment, removecomment, removelike, upsertlike, removememe, upsertreport, upsertmemes } from '../api/meme'
import _, { isNull } from "lodash";
import Header from "../elements/header";
import { useNavigate, useLocation } from "react-router-dom";
import { Link, Navigate } from 'react-router-dom';
import moment from "moment";
import { Loader, Notification, toaster, useToaster, Message, Button, Modal as RModal } from 'rsuite';
import {
  EmailShareButton,
  FacebookShareButton,
  HatenaShareButton,
  InstapaperShareButton,
  LineShareButton,
  LinkedinShareButton,
  LivejournalShareButton,
  MailruShareButton,
  OKShareButton,
  PinterestShareButton,
  PocketShareButton,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  ViberShareButton,
  VKShareButton,
  WhatsappShareButton,
  WorkplaceShareButton
} from "react-share";
import { FacebookIcon, TwitterIcon, WhatsappIcon } from "react-share";
import character1 from '../assets/images/Character1.svg';
import character2 from '../assets/images/Character2.svg';
import character3 from '../assets/images/Character3.png';
import profilepic from '../assets/images/profile_pic.png';
import likeicon from '../assets/images/like_icon.svg';
import telephoneicon from '../assets/images/telephone_icon.svg';
import posticon from '../assets/images/post_icon.png';
import Modal from 'react-modal';

function MyMemes(props) {

  const context = useContext(AppContext);

  let location = useLocation();
  Modal.setAppElement('body');
  console.log(props.memeid, 'currentmeme');

  const [memes, setmemes] = useState([]);
  const [loading, setloading] = useState(false);
  const [usermemes, setusermemes] = useState([]);
  const [comment, setComment] = useState();
  const [userid, setuserid] = useState();
  const [val, setVal] = useState();
  const isLoggedIn = (localStorage.getItem("isLoggedIn") === 'true');
  const localuserid = localStorage.getItem("userid");
  const useravatar = localStorage.getItem("avatar") || '';
  const navigate = useNavigate();
  const [modalmeme, setmodalmeme] = useState(null);
  const [modalmemeindex, setmodalmemeindex] = useState(null);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [userisadmin, setuserisadmin] = React.useState(false);

  const [type, setType] = React.useState('info');
  const [placement, setPlacement] = React.useState('topCenter');
  const [infomessage, setinfomessage] = React.useState('');


  const toaster = useToaster();
  const message = (
    <Message showIcon type={type}>
      {infomessage}
    </Message>
  );



  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      padding: '150px',
      marginRight: '-50%',
      background: 'black',
      transform: 'translate(-50%, -50%)',
    }

  };

  const handleClickLogout = () => {

    localStorage.removeItem("userid");
    localStorage.removeItem("token");
    localStorage.removeItem("emailVerified");
    localStorage.removeItem("avatar");
    localStorage.setItem("isLoggedIn", false);

    navigate("/home");

  };

  function openModal(meme, index) {
    setmodalmeme(meme);
    setmodalmemeindex(index);
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const [open, setOpen] = React.useState(false);
  const [tweet, settweet] = React.useState('');
  const [backdrop, setBackdrop] = React.useState('static');
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(async () => {
    if (isLoggedIn && localuserid) {
      setuserid(localuserid);

    }
    if (!props.currentMeme) {
      try {

        let allmemes = await getmemes({ createduser: localuserid });
        allmemes = _.orderBy(allmemes.data.result, ['createdat'], ['asc']);
        setmemes(allmemes);
        //if (!props.currentMeme) { context.setmemeData(allmemes.data.result); }

      }
      catch (err) {

        console.log(err);

      }
    }
    else {
      setmemes([props.currentMeme]);
    }
    // try {
    //   let usermemes = await getmemes({ createduser: userid });

    //   setusermemes(usermemes.data.result);
    // }
    // catch (err) {



    // }

  }, []);

  const updateFieldChanged = index => e => {


    let newArr = [...memes]; // copying the old datas array
    newArr[index].content = e.target.value; // replace e.target.value with whatever you want to change it to
    setmemes(newArr);
    if (!props.currentMeme) { context.setmemeData(newArr); }
  }

  const updateFieldsubcommentChanged = (index, commentindex) => e => {

    let newArr = [...memes];
    newArr[index].comments[commentindex].subcommentcontent = e.target.value;
    setmemes(newArr);
    if (!props.currentMeme) { context.setmemeData(newArr); }
    else {

    }
  }



  const handleImage = (file) => {

    navigate('/addmeme', {
      state: {
        file: file,
      }
    });
  }

  const handleReset = (inputdatatype) => {

    Array.from(document.querySelectorAll("input")).forEach(


      input => {


        if (inputdatatype == input.name) {
          input.value = "";
          return;
        }

      }

    );

  };

  const memeApprove = async (meme, index, status) => {

    //event.preventDefault();
    // console.log(event);
    // console.log(meme);
    checkuserlogin();

    let body = {
      "_id": meme._id,
      isactive: status
    }


    setloading(true);
    let res = {};
    try {
      res = await upsertmemes(body);
      if (res.data.statusCode === 200) {


        let newArr = [...memes]; // copying the old datas array
        newArr[index] = res.data.result; // replace e.target.value with whatever you want to change it to
        setmodalmeme(res.data.result);
        setmemes(newArr);
        if (!props.currentMeme) { context.setmemeData(newArr); }
        setloading(false);
      }
    } catch (err) {

      setloading(false);

    }




  };


  const saveComment = async (meme, index, comment, inputdatatype) => {
    checkuserlogin();
    //event.preventDefault();
    // console.log(event);
    // console.log(meme);

    console.log(inputdatatype);
    console.log(comment);
    let body = {
      "_id": meme._id,
      "content": meme.content,


      "status": "new",
      "type": "comment"
    }

    if (comment) {

      body.maincommentid = comment.commentid;
      body.type = 'subcomment';
      body.content = comment.subcommentcontent || '';

    }

    if (body.content == '') {
      Array.from(document.querySelectorAll("input")).forEach(

        input => {


          if (inputdatatype == input.name) {

            body.content = input.value;
            return;
          }

        }

      );

    }

    if (_.isUndefined(body.content)) {
      // toaster.push(<Notification closable header={'Error'} type={'error'}>  <span>{'Please Enter Comment'}</span></Notification>, {
      //   placement: 'topEnd'

      // });
      setType('error');
      setinfomessage('Please Enter Comment');
      toaster.push(message, { placement });
      return;
    }
    else if (!_.isUndefined(body.content) && body.content.length == 0) {
      // toaster.push(<Notification closable header={'Error'} type={'error'}>  <span>{'Please Enter Comment'}</span></Notification>, {
      //   placement: 'topEnd'

      // });
      setType('error');
      setinfomessage('Please Enter Comment');
      toaster.push(message, { placement });
      return;
    }
    setloading(true);
    let res = {};
    try {
      res = await upsertcomment(body);
      if (res.data.statusCode === 200) {


        let newArr = [...memes]; // copying the old datas array
        newArr[index] = res.data.result; // replace e.target.value with whatever you want to change it to
        setmodalmeme(res.data.result);
        setmemes(newArr);
        if (!props.currentMeme) { context.setmemeData(newArr); }
        handleReset(inputdatatype);
        setloading(false);
      }
    } catch (err) {

      setloading(false);

    }




  };


  const savetweet = async (title, desc, isactive) => {
    checkuserlogin();

    var body = {

      "title": tweet,
      "desc": desc,
      "isactive": true

    }

    setloading(true);
    let res = {};
    try {
      res = await upserttweet(body);
      if (res.data.statusCode === 200) {

        setloading(false);
        settweet('');
      }
    } catch (err) {

      setloading(false);

    }




  };

  const removememeComment = async (meme, index, comment, type) => {

    checkuserlogin();

    let body = {
      "_id": meme._id,
      "commentid": comment.commentid,

    }
    if (type == 'subcomment') {
      body.reportindex = getIndexs(meme.report, userid, 'commentid', comment.commentid)
      body.commentsindex = getIndexs(meme.comments, userid, 'commentid', comment.commentid);
      body.likesindex = getIndexs(meme.likes, userid, 'commentid', comment.commentid);
      body.reportindex = getIndexs(meme.report, userid, 'commentid', comment.commentid)
    }
    else {

      body.likesindex = getIndexs(meme.likes, userid, 'commentid', comment.commentid).concat(getIndexs(meme.likes, userid, 'maincommentid', comment.commentid));


      body.commentsindex = getIndexs(meme.comments, userid, 'commentid', comment.commentid).concat(getIndexs(meme.comments, userid, 'maincommentid', comment.commentid));

      body.reportindex = getIndexs(meme.report, userid, 'commentid', comment.commentid).concat(getIndexs(meme.report, userid, 'maincommentid', comment.commentid));
    }

    setloading(true);
    console.log(body);
    let res = {};
    try {
      res = await removecomment(body);
      if (res.data.statusCode === 200) {

        console.log(index);
        let newArr = [...memes]; // copying the old datas array
        newArr[index] = res.data.result; // replace e.target.value with whatever you want to change it to
        setmodalmeme(res.data.result);
        setmemes(newArr);
        if (!props.currentMeme) { context.setmemeData(newArr); }

        setloading(false);



        setloading(false);


      }
    } catch (err) {

      setloading(false);

    }




  };

  const checkuserlogin = () => {


    if (!isLoggedIn) {
      // toaster.push(<Notification closable header={'Error'} type={'error'}>  <a href="/login"><span>{'Please Login to Continue'}</span></a></Notification>, {
      //   placement: 'topEnd'

      // });
      setType('error');
      setinfomessage('Please Enter Comment');
      toaster.push(message, { placement });
      return;
    }
  }

  const saveLike = async (meme, index, comment, type, maincomment) => {
    checkuserlogin();

    var isactive = _.filter(meme.likes, function (o) { if (o.type == type && o.user._id == userid && o.commentid == comment.commentid) return o });

    if (isactive.length != 0) {


      let body = {
        "_id": meme._id,
        "userid": userid,
        "type": type,
        "likeindex": getIndexs(meme.likes, userid, "commentid", comment.commentid)
      }
      console.log('isactive');
      setloading(true);
      let res = {};
      try {
        res = await removelike(body);
        if (res.data.statusCode === 200) {

          console.log(index);
          let newArr = [...memes]; // copying the old datas array
          newArr[index] = res.data.result; // replace e.target.value with whatever you want to change it to

          setmemes(newArr);
          if (!props.currentMeme) { context.setmemeData(newArr); }
          setloading(false);





        }
      } catch (err) {


        setloading(false);
      }
    }

    else {


      let body = {
        "_id": meme._id,


        "status": "new",
        "type": type
      }


      if (comment) {
        body.commentsindex = getIndexs(meme.likes, userid, 'commentid', comment.commentid)

        body.commentid = comment.commentid;
      }
      if (maincomment) {

        body.maincommentid = maincomment.commentid;
      }
      setloading(true);
      let res = {};
      try {
        res = await upsertlike(body);
        if (res.data.statusCode === 200) {

          console.log(index);
          let newArr = [...memes]; // copying the old datas array
          newArr[index] = res.data.result; // replace e.target.value with whatever you want to change it to
          setmodalmeme(res.data.result);
          setmemes(newArr);
          if (!props.currentMeme) { context.setmemeData(newArr); }
          setloading(false);





        }
      } catch (err) {


        setloading(false);
      }

    }


  };

  const saveReport = async (meme, index, comment, type, maincomment, value) => {
    checkuserlogin();

    let body = {
      "_id": meme._id,
      "status": "new",
      "type": type,

    }

    if (type == 'memereport' || type == 'memeunreport') {
      var stype = '';
      if (type == 'memereport') { stype = 'memeunreport' } else { stype = 'memereport' }
      body.reportindex = getIndexs(meme.report, userid, 'type', stype)
    }

    if (comment) {

      body.commentid = comment.commentid;
      body.reportindex = getIndexs(meme.report, userid, 'commentid', comment.commentid)
    }
    if (maincomment) {

      body.maincommentid = maincomment.commentid;
    }
    setloading(true);
    console.log(body);
    let res = {};
    try {

      res = await upsertreport(body);
      if (res.data.statusCode === 200) {

        console.log(index);
        let newArr = [...memes]; // copying the old datas array
        newArr[index] = res.data.result; // replace e.target.value with whatever you want to change it to
        setmodalmeme(res.data.result);
        setmemes(newArr);
        if (!props.currentMeme) { context.setmemeData(newArr); }
        setloading(false);





      }
    } catch (err) {


      setloading(false);
    }




  };

  function getIndexs(data, userid, field, value) {

    var indexes = [], i = -1;
    for (var i = 0; i < data.length; i++) {

      if (userid) {

        if (data[i]['user']['_id'] == userid && data[i][field] == value) {
          indexes.push(i);
        }
      }
      else {


        if (data[i][field] == value) {
          indexes.push(i);
        }
      }

    }

    return indexes;
  }



  const removememeLike = async (meme, index, type) => {

    checkuserlogin();



    let body = {
      "_id": meme._id,
      "userid": userid,
      "type": type,
      "likeindex": getIndexs(meme.likes, userid, "type", type)
    }

    console.log(body);
    setloading(true);
    let res = {};
    try {
      res = await removelike(body);
      if (res.data.statusCode === 200) {

        console.log(index);
        let newArr = [...memes]; // copying the old datas array
        newArr[index] = res.data.result; // replace e.target.value with whatever you want to change it to
        setmodalmeme(res.data.result);
        setmemes(newArr);
        if (!props.currentMeme) { context.setmemeData(newArr); }

        setloading(false);




      }

    } catch (err) {

      setloading(false);

    }




  };

  const deletememe = async (meme, index, type) => {

    checkuserlogin();



    let body = {
      "_id": meme._id

    }

    setloading(true);

    let res = {};
    try {
      res = await removememe(body);
      if (res.data.statusCode === 200) {


        let newArr = [...memes]; // copying the old datas array
        _.pullAt(newArr, index); // replace e.target.value with whatever you want to change it to
        ;
        setmemes(newArr);
        if (!props.currentMeme) { context.setmemeData(newArr) }
        setloading(false);

      }
    } catch (err) {

      setloading(false);

    }




  };

  const check = () => {
    //  console.log(userid);
    //console.log(memes[0]);
    var test = _.filter(memes[0].likes, function (o) { if (o.type == 'memelike' && o.user._id == '61ff5933ac8ad93be0302c51') return o })
    // console.log(memes[0].likes, 'memes[0]');
    // console.log(test, 'test');
  }

  return (
    <div class="home">
      <div class="character1">
        <img src={character1} class="w100" alt=""></img>
      </div>
      <div class="character2">
        <img src={character2} class="w100" alt=""></img>
      </div>
      <div class="character3">
        <img src={character3} class="w100" alt=""></img>
      </div>


      <div class="auto_block">
      <div class="post_block">
          <div className="post_hed">
          <div></div>
          <div className="post_logo"><img src={appLogo1} /></div>
            <div className="home_profile">
            <a href="/home" class="hed_link"><span className="icon"><img src={trending1} /> </span> Trending</a>
          
           
              {!modalIsOpen && (
                <div class=" profile">
              <div class="dropdown">
              <a class="dropdown-toggle" role="button" id="dropdownMenuLink"
                data-bs-toggle="dropdown"
                data-toggle="dropdown" aria-expanded="false">
                <img src={useravatar || profilepic} width={39} height={39} />
              </a>

              <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                {/* <a class="dropdown-item" href="#">Profile</a> */}

              
                {/* {isLoggedIn && (
                <Link to="/trend" class="dropdown-item">My Memes</Link>)} */}
                {isLoggedIn && (
                  <Link to="/addmeme" class="dropdown-item"><span className="icon"><img src={add} /> </span>Create Meme</Link>)}
                {!isLoggedIn && (
                  <a href="/login" class="dropdown-item"><span class="material-icons">
                    login
                  </span>Login/SignUp</a>
                )}
                      <a href="#" class="dropdown-item"><span className="icon"><img src={settings} /> </span>Settings</a>
                {isLoggedIn && (
                  <a href="javascript:void(0)" class="dropdown-item" onClick={handleClickLogout}><span className="icon"><img src={logout} /> </span>Logout</a>
                )}
              </div>
            </div>
                </div>
              )}
            </div>
            </div>
            <div className="card_wrp">
            <div class="post_comment">
            <input type="text" placeholder="What's Happening!!"
              onChange={e => settweet(e.target.value)}

            />
            <button class="btn" onClick={() => savetweet()}>
              <img src={posticon} /> Post</button>
          </div>
          </div>

          {memes.map((meme, index) => (
            <div class="post_item" key={meme._id}>
              <div class="post_profile">
                <div class="profile_pic">
                  <img src={meme.createduser.avatar[0].file} />
                </div>
                <h3>{meme.createduser.userName}</h3>
              </div>
              {meme.type && meme.type != 'tweet' &&
                <div class="post_image">
                  <img src={meme.meme.file} class="w100" alt="" />
                </div>}
              <div class="post_actions">
                <div class="post_details">

                  <p class="post_title">{meme.title}</p>
                </div>

                <div class="post_likeblock">
                  {meme.createduser._id == userid ?
                    <div class="like">
                      <span onClick={() => deletememe(meme, index)} class="material-icons active" >delete</span>

                    </div>
                    :
                    null
                  }
                  <div class="like">
                    <img src={likeicon} class="img" />
                    <span class="text">{_.filter(meme.likes, function (o) { if (o.type == 'memelike') return o }).length} Likes</span>
                  </div>
                  <div class="comments">
                    <img src={telephoneicon} class="img" />
                    <a class="text" data-toggle="modal" data-target="#exampleModal" onClick={() => openModal(meme, index)}>{_.filter(meme.comments, function (o) { if (o.type == 'comment') return o }).length} Comments</a>
                  </div>
                </div>
              </div>
              <div class="post_comment">
                <input type="text" placeholder="Add Comments"
                  name={'meme' + index + 'comment'}
                  value={meme.content}
                  onChange={updateFieldChanged(index)}

                />
                <button class="btn" onClick={() => saveComment(meme, index, null, 'meme' + index + 'comment')}>
                  <img src={posticon} /> Post</button>
              </div>
            </div>
          ))}
            </div>
      
    
        {/* <div class="post_comment">
          <input type="text" placeholder="What's Happening!!"
            onChange={e => settweet(e.target.value)}

          />
          <button class="btn" onClick={() => savetweet()}>
            <img src={posticon} /> Post</button>
        </div> */}
      
         


   
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
        shouldCloseOnOverlayClick={false}
      >
        {modalmeme ?
          <div class="comments_popup" id="exampleModal" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <a class="close popup_close" data-dismiss="modal" aria-label="Close" onClick={closeModal}>
              <span class="material-icons"> close
              </span>
            </a>
            <div class="modal-dialog modal-dialog modal-xl">
              <div class="modal-content">

                <div class="modal-body">
                  <div class="comment_wrp">

                    {modalmeme.type && modalmeme.type != 'tweet' ?
                      <div class="post_img_block">
                        <img src={modalmeme.meme.file} class="post_img" alt="" />
                      </div>
                      : <div class="post_img_block">

                        <img src={autoimg} class="post_img" alt="" />
                      </div>
                    }
                    <div class="comment_details">
                      <div class="post_profile">
                        <div class="profile_pic">
                          <img src={modalmeme.createduser.avatar[0].file} />
                        </div>
                        <h3>{modalmeme.createduser.userName}</h3>
                        <a class="post_options" href="#">
                          <span class="material-icons"> more_horiz </span>
                        </a>
                      </div>
                      <div class="comment_lists">
                        {_.filter(modalmeme.comments, function (o) { if (o.type == 'comment') return o }).map((comment, indexcomment) => (

                          <div class="comment_list" key={comment.commentid}>
                            <div class="post_profile">
                              <div class="profile_pic">
                                <img src={comment.user.avatar[0].file} />
                              </div>
                              <div class="post_title">
                                <h3>{comment.user.userName}
                                  <span class="comment"> {comment.content}
                                    🏏❤️
                                  </span>
                                </h3>
                                <div class="comment_actions">
                                  <a href="#" class="cmt_act">{moment(comment.uploadedAt).fromNow()}</a>
                                  {/* <a href="#" class="cmt_act2">5,736 likes</a> */}
                                  {!_.isUndefined(userid) ?

                                    <div class="cmt_act2">

                                      <span class={`material-icons ${_.filter(modalmeme.likes, function (o) { if (o.type == 'commentlike' && o.user._id == userid && o.commentid == comment.commentid) return o }).length != 0 ? "active" : ""}`} onClick={() => saveLike(modalmeme, modalmemeindex, comment, 'commentlike', null)}> thumb_up </span>
                                      <span class={`material-icons ${_.filter(modalmeme.likes, function (o) { if (o.type == 'commentdislike' && o.user._id == userid && o.commentid == comment.commentid) return o }).length != 0 ? "active" : ""}`} onClick={() => saveLike(modalmeme, modalmemeindex, comment, 'commentdislike', null)}>
                                        thumb_down
                                      </span>
                                    </div>
                                    : null}

                                </div>
                                {/* <div class="post_comment">
                                  <span class="material-icons">sentiment_satisfied_alt</span>

                                  <input id="inputEmail" type="text" class="comment_input" placeholder=" Reply comment…"   >
                                  </input>
                             
                                  <div class="comment_actions">
                                    <a href="#" class="cmt_act2">Reply</a>

                                  </div>
                                </div>
                                <div class="comment_actions">


                                  <div class="rep_line"></div>
                                  <a href="#" class="cmt_act2">View all replies ({_.filter(modalmeme.comments, function (o) { if (o.type == 'subcomment' && o.maincommentid == comment.commentid) return o }).length})</a>
                                </div> */}

                              </div>
                            </div>
                          </div>
                        ))}

                      </div>
                      <div class="post_share">
                        <div class="icons">
                          <div>
                            <a href="#" class="post_icon">
                              {_.filter(modalmeme.likes, function (o) { if (o.type == 'memelike' && o.user._id == userid) return o }).length != 0 ?


                                <span class="material-icons active" onClick={() => removememeLike(modalmeme, modalmemeindex, 'memelike', null)}>favorite</span> :

                                <span class="material-icons" onClick={() => saveLike(modalmeme, modalmemeindex, null, 'memelike', null)}>favorite_border</span>}
                              {/* <span class="material-icons"> favorite_border </span> */}
                            </a>

                            {modalmeme.type && modalmeme.type != 'tweet' &&
                              <a href={modalmeme.meme.file} target="_blank" class="post_icon">
                                <span class="material-icons">  get_app</span>
                              </a>}
                            <a href="#" onClick={handleOpen} class="post_icon">
                              <span class="material-icons"> share</span>
                            </a>
                            <a href="#" class="post_icon">
                              <span class="material-icons">open_in_new</span>
                            </a>
                          </div>
                          <div>
                            {/* <a href="#" class="post_icon2">
                              <span class="material-icons"> bookmark_border</span>
                            </a> */}
                            {/* <a href="#" class="post_icon2">
                              <span class="material-icons"> report</span>
                            </a> */}

                            {
                              !_.isUndefined(userid) ?

                                modalmeme.createduser._id != userid ?

                                  _.filter(modalmeme.report, function (o) { if (o.type == 'memereport' && o.user._id == userid) return o }).length != 0 ?
                                    <a href="#" class="post_icon2">
                                      <span class="material-icons active" onClick={() => saveReport(modalmeme, modalmemeindex, null, 'memeunreport', null, null)}> report</span>
                                    </a>
                                    :
                                    <a href="#" class="post_icon2">
                                      <span class="material-icons" onClick={() => saveReport(modalmeme, modalmemeindex, null, 'memereport', null,)}> report</span>
                                    </a>

                                  :
                                  null
                                :
                                null
                            }
                          </div>
                        </div>
                        <p>
                          <a href="#">
                            {_.filter(modalmeme.likes, function (o) { if (o.type == 'memelike') return o }).length} likes</a>
                        </p>
                        <div class="comment_actions">
                          {modalmeme.type && modalmeme.type != 'tweet' &&
                            <a href="#" class="cmt_act">{moment(modalmeme.meme.uploadedAt).fromNow()} </a>
                          }
                        </div>
                      </div>
                      <div class="post_comment">
                        <span class="material-icons">sentiment_satisfied_alt</span>

                        <input id="inputEmail" type="text"
                          name={'meme' + modalmemeindex + 'comment1'}

                          onChange={updateFieldChanged(modalmemeindex)}
                          class="comment_input" placeholder=" Add a comment…"   >
                        </input>
                        <input type="submit" value="Post" class="post_btn" onClick={() => saveComment(modalmeme, modalmemeindex, null, 'meme' + modalmemeindex + 'comment1')}></input>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          : null}
      </Modal >
      <RModal backdrop={backdrop} keyboard={false} open={open} onClose={handleClose}>
        <RModal.Header>
          <RModal.Title>Share Meme</RModal.Title>
        </RModal.Header>

        <RModal.Body>
          <div className="post_settings">
            <FacebookShareButton
              url={"https://www.google.com/"}

              hashtag={"#freepakoda"}
              description={"aiueo"}

            >
              <FacebookIcon size={32} round />
            </FacebookShareButton>

            <TwitterShareButton
              title={"test"}
              url={"https://www.google.com/"}
              hashtags={["freepakoda"]}
            >
              <TwitterIcon size={32} round />

            </TwitterShareButton>
            <WhatsappShareButton
              url={"http://www.camperstribe.com"}
              title={"CampersTribe - World is yours to explore"}
              separator=":: "

            >
              <WhatsappIcon size={32} round />
            </WhatsappShareButton>
          </div>
        </RModal.Body>

      </RModal>
    </div >


  );
}

export default MyMemes;
