import React, { useContext, useState, useRef, useEffect } from 'react';

import axios from 'axios';
import { Link, Navigate } from 'react-router-dom';
import { GlobalContext } from "../context/Provider";
import { login } from "../context/actions/Auth";
import { useNavigate, useLocation } from "react-router-dom";
import ImageEditor from "@toast-ui/react-image-editor";
import 'tui-image-editor/dist/tui-image-editor.css'
import { getmemes, uploadmemes } from '../api/meme'
import _ from "lodash";
import Header from "../elements/header";
import moment from "moment";
import { Loader, Notification, toaster } from 'rsuite';
// const Jimp = require("jimp");
import Jimp from "jimp/browser/lib/jimp";
import logo1 from '../assets/images/fplogo.jpg';
import "tui-color-picker/dist/tui-color-picker.css";
const AddMeme = (props) => {
    const ref = useRef(null);

    let location = useLocation();

    const file = location.state?.file;
    console.log(file);
    // const [Title, setTitle] = useState('');
    // const [Content, setContent] = useState('');
    const [state, setState] = useState({
        Title: '',
        Content: '',
        required: false,
        img: null,
        loading: false,
    })

    const {
        Title,
        Content,
        required,
        img,
        loading


    } = state

    const disabled =

        !Title 
        // ||        !Content
 
        || !img

    const handleChange = (key, value) => {
        setState(pre => ({ ...pre, [key]: value }))
    }

    const mousedown = (props) => {
        console.log(props);
    }

    useEffect(() => {
        const wrapper = ref.current.getInstance();

        if (file) {
            wrapper.loadImageFromURL(file, 'My sample image');
        }
    }, []);



    const navigate = useNavigate();

    const handleSubmit = async event => {
        console.log('handle submit');
        handleChange('loading', true)



        const wrapper = ref.current.getInstance();
        if (!_.isUndefined(wrapper.ui.imageSize.newWidth)) {


            handleChange('img', wrapper.toDataURL({ format: 'jpeg' }));


        }
        setTimeout(() => {
          
         }, 5000);

        if (disabled) {
            console.log('return');
            handleChange('required', true)
            handleChange('loading', false)
            return;
        }



        if (img) {

            // let watermark = await Jimp.read(appLogo);

            // watermark = watermark.resize(362, 162);



            const image = await Jimp.read(img);


            // image.composite(watermark, 0, 0, {
            //     mode: Jimp.BLEND_SOURCE_OVER,
            //     opacityDest: 1,
            //     opacitySource: 0.2
            // })
            var imglogo = await image.getBase64Async(Jimp.AUTO);



            var body = {

                "title": Title,
                "desc": Content||'',
                "isactive": true,
                "file": imglogo
            }

            let res = {};
            try {
                res = await uploadmemes(body);
                if (res.data.statusCode === 200) {

                    handleChange('loading', false)
                    navigate("/home");
                }
            } catch (err) {
                handleChange('loading', false)
                console.log(res);

            }
        }










    };
    return (


        <div class="addmeme page">
            {/* <div class="clock"><img src={imgclock} alt="" /></div>
            <div class="plant"><img src={imgplant} alt="" /></div> */}
            <div className='meme_header'>
                <div class="wrapper">
                    <Link to="/home" className="button"><img src={logo1} class="logo1" /></Link>
                    <h2>Create Meme</h2>
                    <Link to="/home" className='backBtn'><span className="material-icons">
                        arrow_back_ios
                    </span>Back</Link>
                </div>
            </div>
            <div class="content">
                <div class="wrapper">
                    <div class="addmeme_form">
                        <div class="flex">
                            <div class="form-field1">
                                <input type="text" placeholder="Meme Title" onChange={e => handleChange('Title', e.target.value)} autoFocus />
                                {required && !Title && (
                                    <div>
                                        <small> Title is required!</small>
                                    </div>
                                )}
                            </div>
                            {/* <div class="form-field2">
                                <input type="text" placeholder="Description" onChange={e => handleChange('Content', e.target.value)} autoFocus />
                                {required && !Content && (
                                    <div >
                                        <small> Description is required!</small>
                                    </div>
                                )}
                            </div> */}
                        </div>




                    </div>

                    {loading ? <div id="loaderInverseWrapper" >
                        <Loader backdrop inverse center content="loading..." />
                    </div> : null}

                    {required && !img && (
                        <div >
                            <small style={{ color: "#fff" }}> Meme image is required</small>
                        </div>
                    )}
                    <div class="image_editer">

                        <ImageEditor

                            ref={ref}
                            onObjectactivated={props => console.log(props)}
                            onObjectadded={props => console.log(props)}
                            onObjectActivated={props => console.log(props)}
                            onObjectAdded={props => console.log(props)}
                            onMousedown={props => console.log(props)}
                            includeUI={{

                                // menu: ['shape', 'filter', 'text', 'icon', 'draw', 'rotate', 'flip', 'crop', 'resize'],
                                // initMenu: 'filter',
                                uiSize: {
                                    width: '1050px',
                                    height: '500px',
                                },
                                menuBarPosition: 'right',
                                cssMaxWidth: 1200,
                                cssMaxHeight: 800,
                                selectionStyle: {
                                    cornerSize: 20,
                                    rotatingPointOffset: 70,
                                },


                            }}


                        />

                    </div>
                    <div class="btns">
                        <a class="btn submit" onClick={handleSubmit}>Submit</a>
                        <Link to="/home" class="btn cancel">Cancel</Link></div>
                </div>
            </div>
            <div className='footer addmeme_footer'>
                <p>© Copy Rights 2022 Free Pakoda. All Rights Reserved.</p>
                {/* <ul>
                    <li><a href="/aboutus"> About Us</a> </li>
                    <li><a href="/terms"> Terms of Service</a> </li>
                    <li> <a href="/privacy">Privacy Policy</a></li>
                </ul> */}
            </div>




        </div>

    );
}
export default AddMeme;
