export const defaultConfig = {
 // API_URL: 'http://localhost:9004/api/'
     API_URL: 'https://150r95frq4.execute-api.us-east-2.amazonaws.com/dev/api/', // dev url
   
};

export const App = {
    config: defaultConfig,
};

window.env = window.env || defaultConfig;
App.config = { ...window.env };

export const API_URL = () => App.config.API_URL;
